 
img {
  font-size:.75rem;
  font-style: italic;
}

.App {
  background: #fff; 
  min-height: 100vh;
  width:100%;
}  

.page-breadcrumb ul {
  margin-bottom:0;
}

.toast-container {
  position: fixed;
  bottom: 10px;
  right: 0px; 
}

.toast-container .toast-header {
  background-color:var(--primary);  
  color:#fff; 
} 

.toast-container .error .toast-header {
  background-color:var(--danger) !important;
} 

/* CUSTOM TAB */
/* [grid] letakkan .custom-tab di row */
.custom-tab {
  background: #fff;  
  padding-left:0;
} 

.custom-tab li {
  display:inline-block; 
  min-width: 142px;
  padding:26px 15px 5px 15px; 
}

/* [grid] letakkan .active di col yang active*/
.custom-tab li.active {
  border-bottom: 7px solid var(--primary);
}

.custom-tab li.active a {   
  color:#4F4F4F;
}

.custom-tab li a {    
  font-size:21px; 
  color:#979797;  
  font-weight: bold;
}

.custom-tab a:hover {
  text-decoration: none;
}

@media screen and (max-width: 540px) {

  .toast-container {
    bottom:90px;
  }

  .toast {
    max-width: 100% !important; 
    margin:0 10px; 
  }

  .fixed-bottom-nav {
    width:100%; 
    position: fixed;
    bottom:0;
    background:#fff;
    padding:18px; 
  }
}

/* BREADCRUMB */
.page-breadcrumb {
  list-style-type:none;
  margin-bottom:16px;
  padding:0;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden; 
}

.page-breadcrumb li {
  display:inline;
}

.page-breadcrumb li .caret {
  /* border:1px solid red; */
  margin-left:5px;
  color:#828282;
  font-size:10px;
}

.page-breadcrumb li a {
  color:#979797;
  font-size:.875rem;
}

.page-breadcrumb li a.active {
  color:#565656;
  font-weight: bold;
}

.limit-1-line {
  /* limit text */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.limit-2-line {
  /* limit text */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.limit-3-line {
  /* limit text */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

  
.pac-container {
  z-index: 10000 !important;
}

/* override color page link */
.page-item.active .page-link {
  background-color: var(--primary) !important;
  color: #fff !important;
}