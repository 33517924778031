.app-bottom-nav {
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
 
    background:#fff; 
    z-index: 1001;  
    border:1px solid #ccc;
}


.app-bottom-nav .bottom-nav-wrapper {  
    margin:5px 0 0px 0;
    text-align: center; 
    cursor:pointer;   
    font-size:.75rem; 
} 

.app-bottom-nav .bottom-nav-text {
    padding-top:2px;
    color: #959595;
}  

.app-bottom-nav .active .bottom-nav-text {
    color: #464646;
}

.app-bottom-nav .bottom-nav-img {  
    margin:auto;
    border-radius:4px;
    background:#fff;
    height:24px; 
    width:24px;
    padding:3px 0;
}

.app-bottom-nav .active .bottom-nav-img { 
    /* background:var(--primary); */
}
 
.app-bottom-nav img {  
    height:16px;  
}

.app-bottom-nav .active img {  
    /* background:var(--primary); */
}

/* .app-bottom-nav .active .bottom-nav-img {  */
    /* background:var(--primary); */
/* } */

/* .app-bottom-nav img {
    width:20px;
} */