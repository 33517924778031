.address-container {
    /* border:1px solid red; */
}

.address-header {
    margin-bottom:16px;
}

.btn-add-address {
    float:right;
}

.address-pinpoint-flag {
    position:absolute;
    bottom:54px;
    left:15px;
}


/* Small Device */
@media (max-width: 767.98px) { 
    .btn-add-address {
        width:100%;
    }

    .address-pinpoint-flag {
        display:none;
    }
}