.ppob {
    text-align: center;
  }
  
  .ppob {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .ppob-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .ppob-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .ppob-link {
    color: #61dafb;
  }
  
  @keyframes Beranda-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  
    
  }

  .ppob-custom-tab{
    background:#FFFFFF;  
    padding-top: 30px;
    text-align: center;
    

     } 

  .ppob-custom-tab-Nav-Item-active{
    display:inline-block; 
    width: 100%;
    border-bottom: 3px solid var(--primary);
    padding-bottom: 20px;
    text-align: center;
    }

  .ppob-custom-tab-Nav-Item{
      display:inline-block; 
      width: 100%;
      /* padding:10px 5px 10px 50px; 
      /* border-bottom: 7px solid var(--secondary); */
     

    }

  .ppob-custom-tab-Nav-Item a{
    
        font-size: 24px;
        line-height: 28px;
        color: #B3B3B3;
        font-weight: 500;
        text-decoration: none;
        
      }
    
      .ppob-custom-tab-Nav-Item-active a{
    
        font-size: 24px;
        line-height: 28px;
        color: #4F4F4F;
        font-weight: 500;
        text-decoration: none;
        padding-bottom: 20px;
        
       
      }

      .placeholderStyle{
        font-size: 14px;
        font-weight: bold;
        color: rgba(79, 79, 79, 0.5);
      }

      .scrollmenu {
        background-color: #FFFFFF;
        overflow: auto;
        white-space: nowrap;
      }
      
      .scrollmenu a {
        display: inline-block;
        color: #B3B3B3;
        text-align: center;
        padding: 14px;
        font-weight: 500;
        text-decoration: none;
      }

      .scrollmenu-active{
        display: inline-block;
        color: #4F4F4F !important;
        font-weight: 500;
        text-align: center;
        padding: 14px;
        text-decoration: none;
        border-bottom: 3px solid var(--primary);
    
      }
      
      .scrollmenu a:hover {
        background-color: #777;
      }
      