.delivery-card {
    background: white;
    margin-bottom: 20px; 
    padding:16px 20px 16px 45px;
    border-bottom: 2px solid rgba(224, 224, 224, 0.6);
    /* border-radius: 3px; */
    /* border: 1px solid #ccc; */
}

.delivery-card
.delivery-section-title { 
    font-weight: 500;
    margin-left:-16px;  
    padding-bottom:14px;
    font-size:17px;
}

.delivery-card 
.product-title {
    font-weight: bold;
    color: #4F4F4F; 
}

.delivery-card 
.product-price {  
    font-weight: bold;
    color: var(--primary);
}

.delivery-card
.total-price {
    text-align: right; 
    font-weight:bold;
}
/* address list card */

.address-list-card {
    position: relative;
    font-size:14px;
}

.address-list-card
.address-label,
.address-list-card
.address-name {
    font-weight: bold;
    color:#4F4F4F;  
    font-size:16px;
} 

.address-list-card
.address-phone { 
    padding-top:7px;
    color:#565656;
}

.address-list-card
.address-address { 
    padding-top:7px;
    color:#828282;
    line-height: 16px;
}

.metode-pembayaran-list{
    list-style-type: none;
    padding:0;
}

.metode-pembayaran-list li {  
    padding:10px 0;
    cursor:pointer;
} 

.delivery-btn-next{
    margin-top:32px;
    width:205px;
    float: right;
}

.delivery-summary-title {
    margin-bottom:15px;
} 

.delivery-summary-line {
    background:#C4C4C4;
    width:100%;
    height:1px;
    /* margin-left:-16px; */
    margin-top:14px;
}

.delivery-top-space {
    margin-top:1px;
    margin-left: 50px;
}

.delivery-option-text {
    margin-bottom:14px;
}

.hr-line{
    width: 100%;
    height: 3px;
    background: rgba(224, 224, 224, 0.6);
}

.delivery-card-2 {
    background: white;
    margin-bottom: 20px; 
    padding:16px 45px 16px 45px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 1px 0px 3px -1px rgba(193, 189, 189, 0.25);
    border-radius: 8px;
    /* border: 1px solid #ccc; */
}

.delivery-card-2
.delivery-section-title { 
    font-weight: bold;
    margin-left:0px;  
    padding-bottom:14px;
    font-size:20px;
}

.delivery-card-2 
.product-title {
    font-weight: bold;
    color: #4F4F4F; 
}

.delivery-card-2
.product-price {  
    font-weight: bold;
    color: var(--primary);
}

.delivery-card-2
.total-price {
    text-align: right; 
    font-weight:bold;
}

.listPesananContainer{
    margin-left: 50px;
}

.top-Judul{
    margin-top: 29px;
    font-size: 24px;
    line-height: 28px;
    color: #4F4F4F;
    margin-left: 40%;
    font-weight: 500;
}
@media (max-width: 991.98px) {

    .delivery-top-space {
        margin-top:18px;
        margin-left: 0px;
    }

    .hr-line{
        margin-left: 0px;
        }

        .listPesananContainer{
            margin-left: 0px;
        }

        .top-Judul{
            margin-left: 0px;
            text-align: center;
        }
    
}