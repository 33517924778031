.store-item-card { 
    background:#FFF;
    border: 1px solid #D8D8D8;
    border-radius: 8px;
}

.store-item-card .store-item-image { 
    text-align: center;
}

.store-item-card .store-item-image img { 
    width:100%;
    cursor: pointer;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.store-item-card .store-item-name {
    font-size:.75rem; 
    padding: 7px 3px;
    text-align: center;
    height: 30px;
    line-height: 20px;
}

.store-item-card .store-item-name a {
    color:#000000;
} 