.Promo {
    text-align: center;
  }
  
  .Promo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Beranda-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .Promo-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Promo-link {
    color: #61dafb;
  }
  
  @keyframes Beranda-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  
    
  }

  .beritapromosiContainer{

    position: absolute;
    width: 100%;
    height: 700px;
    
  }

  .TabsContainer{
      position: absolute;
      width: 100%;
      top: 80px;
  }

  .Tabsmenu1{
      position: absolute;
      top: 30px;
      left: 35px;
      width: 142px;
      height: 40px;
      background-color: var(--primary);
      font-size: 14px;
      color: white;
      font-weight: bold;
      border: 1px solid var(--primary);
      border-radius: 5px;
  }

  .Tabsmenu1:hover{
      background-color: #0b524b;
  }

  .Tabsmenu2{
    position: absolute;
    top: 30px;
    left: 200px;
    width: 142px;
    height: 40px;
    background-color: #ffffff;
    font-size: 14px;
    color:#4F4F4F;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
}

.Tabsmenu2:hover{
    background-color: #0b524b;
}

.Linemenu{
    position: fixed;
    top: 130px;
    width: 66%;
    height: 1px;
    background-color: #E0E0E0;
}

.cardPromo1{
    position: fixed;
    top: 120px;
    left: 20px;
    width: 270px;
    height: 310px;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 0px;

}
 
.cardPromo2{
    position: absolute;
    top: 120px;
    left: 310px;
    width: 270px;
    height: 310px;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 0px;
}

.cardPromo3{
    position: absolute;
    top: 120px;
    left: 600px;
    width: 270px;
    height: 310px;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 0px;
}


.cardjudul{
    position: absolute;
    top: 170px;
    left: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #4F4F4F;
}

.cardddesk{

    position: absolute;
    top: 195px;
    left: 15px;
    width: 269px;
    height: 200px;
    font-size: 12px;
    color: #4F4F4F;
}

.cardperiode{
    position: absolute;
    top: 235px;
    left: 15px;
    width: 269px;
    height: 200px;
    font-size: 11px;
    color: #FE8606;
}


.Tabsmenuberita1{
    position: absolute;
    top: 30px;
    left: 35px;
    width: 142px;
    height: 40px;
    background-color: #ffffff;
    font-size: 14px;
    color: #4F4F4F;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
}

.Tabsmenuberita1:hover{
    background-color: #0b524b;
}

.Tabsmenuberita2{
  position: absolute;
  top: 30px;
  left: 200px;
  width: 142px;
  height: 40px;
  background-color: var(--primary);
  font-size: 14px;
  font-weight: bold;
  color:white;
  border: 1px solid var(--primary);
  border-radius: 5px;
}

.Tabsmenuberita2:hover{
  background-color: #0b524b;
}

.rowBerita{
  margin-top: 120px;
  width: 100px;
  height: 310px;
  background-color: white;
  border: 1px solid #E0E0E0;
  border-radius: 0px;
  
}

.Listpromo{
  
  width: 100%;
  margin-top: 100px;
}

.cardPromolist{
  position: absolute;
  top: 100px;
  left: 17px;
  margin-top:10px;
  
  width: 260px;
  height: 310px;
  background-color: white;
  border: 1px solid #E0E0E0;
  border-radius: 0px;

}


/* STYLE BARU */

.menuContainer{
  background-color: white;
  width: 100%;
  height: auto;
  
  }

.cardpromodate{
  margin-left: 10px;
  font-size: 10px;
  color: #BDBDBD;
}

.cardpromojudul{
  margin-top: 15px;
  height: 45px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 140%;
  color: #4F4F4F;
  padding-right: 13px;

   /* limit text  */
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   overflow: hidden;
   margin-left: 13px;

}

.promo-lainnya
.cardpromojudullain{
  margin-top: 15px;
  height: 45px;

  font-size: 16px;
  font-weight: bold;
  line-height: 140%;
  color: #4F4F4F;

   /* limit text  */
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   overflow: hidden;
   margin-left: 13px;
   margin-right: 13px;

}

.cardpromodetail{
  margin-left: 10px;
  width: 250px;
  height: 40px;
  font-size: 14px;
  line-height: 140%;
  color: #4F4F4F;
  margin-top: 5px;

   /* limit text  */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-left: 13px;

}

.promo-lainnya
.cardpromodetaillain{
  margin-left: 10px;
  width: 250px;
  height: 40px;
  font-size: 14px;
  line-height: 140%;
  color: #4F4F4F;

   /* limit text  */
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   overflow: hidden;
   margin-left: 13px;

}

.cardpromobutton{
  font-size: 16px !important;
  margin-top: 0px;   
  border-radius: 0px 0px 6px 6px !important;
 
} 

.detailContainer{
  width: 100%;
  height: auto;
  margin-top: 0px;
  background-color: white;
}

.promoBreadcrumb{
  padding-top: 10px;
  padding-left: 10px;
  width: 100%;
  height: 50px;
  background-color: white;
  font-size: 14px;
  color: #565656;
  
}

.deskContainer{
  width: 100%;
  height: auto;
  margin-top: 3px;
  background-color: white;
}

.deskJudul{
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  
  font-size: 36px;
  font-weight: bold;
  line-height: 140%;
  color: #4F4F4F;

}

.deskdate{
  margin-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
  
  font-size: 14px;
  line-height: 140%;

  color: #BDBDBD;
}

.deskPromo{
  margin-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 18px;
  line-height: 25px;
  color:#4F4F4F;
}

.infopromoContainer{
  margin-top: 50px;
  padding-top: 10px;
  padding-left: 10px;
  width: 100%;
  height: 150px;
  background-color: white;
  font-size: 14px;
  color: #4F4F4F;
}



.infopromoDate{
  margin-top: 20px;
  margin-left:10px ;
  width: 270px;
  height: 75px;
  background-color: #F2F2F2;
  border-radius: 5px;
}

.promo-lainnya{
  margin-top: 24px;
  padding: 10px 24px; 
  width: 100%;
  height: auto;
  background-color: white;

  
} 

.lihatProdukbutton{
  margin-left: 0px;
  margin-top: 10px;
  width: 270px;
  height: 35px;
  background-color: var(--primary);
  border: solid 1px var(--primary);
  border-radius: 5px;
  font-size: 12px;
  color: white;
}

.periodepromo{
  padding-left: 12px;
  font-size :12px;
  color: #FE8606;
  height:30px;
  word-spacing: -1px !important;


}

.listPromoTest{
  margin-top: 40px;
  margin-bottom: 20px;
  width: 282px;
  height:360px;
  background-color: white;
  border: 1px solid #E0E0E0;
  border-radius: 6px;
}

.tab-news-col {
  margin:0 1px; 
  padding:16px; 
  font-weight: 500;
  text-align: center;
  color: #979797;
  
  
}

.tab-news-col.active {
  color:#4F4F4F;
  border-bottom: 2px solid var(--primary);
}

.tab-news-col.href:hover{
  text-decoration: none;
}


.listPromoLainnya{ 
  height:395px;  
  margin: 0 5px 20px 5px; 
  border: 1px solid #E0E0E0; 
  border-radius: 6px;
} 

.promo-lain-img {
  height:231.5px; 
}

.promo-lain-img img{

  cursor: pointer;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

}
 
.promoSlideContainer .react-multi-carousel-item:first-child .listPromoLainnya{ 
  margin-left: 0; 
 
}
 

.promo-tab {
  margin-left:-15px;
  margin-right:-15px;
}

.promo-tab li {
  text-align:center;
}

.emptyState{
padding-left: 400px;
padding-top: 40px;
}

.promo-lainnya-title { 
  font-size:21px; 
  line-height:19px;
  padding-top:24px;
  padding-bottom:24px;
  
}

.imgContent{
  width: 280px;
  height: auto;
}

.imgContent img{
  cursor: pointer;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

}


.ShareButton{
  width: 70%;
  padding-left: 30%;
  padding-bottom: 20px;
}

.promo-lihatsemua{
  text-align: right;
  padding: 25px 0px 10px 0;
}

@media (max-width: 575.98px) {
  .emptyState{
    padding:50px;
  }  

  .infopromoDate p{
    font-size: 14px !important;
  }

  .infopromoContainer { 
    width: 100%;
    margin-top:10px;
    
    }

  .infopromoDate{
    background-color: white;
    width: 300px;
    margin-top: 16px;
    margin-left: 7px;
    height: 40px;
    
    
  }

  .promo-lainnya-title {
    font-size: 18px;
    padding-right: 0px; 
    margin-left: -27px;
    padding-left: 5px;
  }

  .imgContent{
    width: 127px;
    height: 94px;
  }

  .listPromoTest{
    width: 100%;
    height: 117px;
    padding:12px;
    margin: 0px 0px 10px;
    box-sizing: border-box;
    border: 1px solid #D8D8D8;
    border-radius: 6px;

  }

  .cardpromojudul{
    font-size: 14px;
    font-weight: bold;
    width: 96%;
    margin-top: 0px;
    margin-bottom: 2px;
    line-height: 16.41px;
    padding-left: 13px;
    height: 32px;
    
  }

  .promo-lainnya
  .cardpromojudullain{
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 2px;
    line-height: 16.41px;
    margin-right: 10px;
    height: 32px;
  }

  .cardpromodetail{
    margin-top: 0px;
    font-size: 14px;
    width: 96%;
    padding-bottom: 13.19px;
    padding-left: 13px;
    
  }

  .promo-lainnya
  .cardpromodetaillain{
    margin-top: 5px;
    font-size: 12px;
    width: auto;
    padding-bottom: 13.19px;
    padding-left: -2px;
    height:35px;
    overflow:hidden;
    margin-right: 10px;

  }

  .ShareButton{
    padding-left: 0px;
    margin-left: 30%;
  }

  .deskJudul{
    font-size: 16px;
    padding-left: 7px;
  }

  .deskdate{
    margin-top: 8px;
    padding-left: 7px;
  }

  .deskPromo{
    padding-left:7px;
    padding-right: 7px;
    }

  .promo-lain-img{
    height:120px; 

  }
  .promo-lain-img img{

    cursor: pointer;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
 
}

.listPromoLainnya{
  height:auto;
   border-radius: 8px !important;
 
  
    
}

.promo-carousel{
  margin-left: -25px;
  width: auto;
  padding-left: 0px;
  margin-right: -35px;
}

.cardpromobutton{
  margin-top:20px;
  border-radius: 0px 0px 6px 6px !important;
    
}

.promo-lainnya{
  margin-top:4px;
}

.periodepromo{
  padding-left: 2px;
  margin-top: 7px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
}

.promo-lihatsemua{
  padding-top: 0px;
  margin-right: -40px;
  margin-top: 20px;
}
   
}
