.Berita {
    text-align: center;
  }
  
  .Berita {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Beranda-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .Berita-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Berita-link {
    color: #61dafb;
  }
  
  @keyframes Beranda-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  
    
  }

  .beritapromosiContainer{

    position: absolute;
    width: 100%;
    height: 700px;
    
  }

  .TabsContainer{
      position: absolute;
      width: 100%;
      top: 80px;
  }

  .Tabsmenu1{
      position: absolute;
      top: 30px;
      left: 35px;
      width: 142px;
      height: 40px;
      background-color: var(--primary);
      font-size: 14px;
      color: white;
      font-weight: bold;
      border: 1px solid var(--primary);
      border-radius: 5px;
  }

  .Tabsmenu1:hover{
      background-color: #0b524b;
  }

  .Tabsmenu2{
    position: absolute;
    top: 30px;
    left: 200px;
    width: 142px;
    height: 40px;
    background-color: #ffffff;
    font-size: 14px;
    color:#4F4F4F;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
}

.Tabsmenu2:hover{
    background-color: #0b524b;
}

.Linemenu{
    position: fixed;
    top: 130px;
    width: 66%;
    height: 1px;
    background-color: #E0E0E0;
}

.cardPromo1{
    position: fixed;
    top: 120px;
    left: 20px;
    width: 270px;
    height: 310px;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 0px;

}
 
.cardPromo2{
    position: absolute;
    top: 120px;
    left: 310px;
    width: 270px;
    height: 310px;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 0px;
}

.cardPromo3{
    position: absolute;
    top: 120px;
    left: 600px;
    width: 270px;
    height: 310px;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 0px;
}



.cardjudul{
    position: absolute;
    top: 170px;
    left: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #4F4F4F;
}

.cardddesk{

    position: absolute;
    top: 195px;
    left: 15px;
    width: 269px;
    height: 200px;
    font-size: 12px;
    color: #4F4F4F;
}

.cardperiode{
    position: absolute;
    top: 235px;
    left: 15px;
    width: 269px;
    height: 200px;
    font-size: 11px;
    color: #FE8606;
}




.Tabsmenuberita1{
    position: absolute;
    top: 30px;
    left: 35px;
    width: 142px;
    height: 40px;
    background-color: #ffffff;
    font-size: 14px;
    color: #4F4F4F;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
}

.Tabsmenuberita1:hover{
    background-color: #0b524b;
}

.Tabsmenuberita2{
  position: absolute;
  top: 30px;
  left: 200px;
  width: 142px;
  height: 40px;
  background-color: var(--primary);
  font-size: 14px;
  font-weight: bold;
  color:white;
  border: 1px solid var(--primary);
  border-radius: 5px;
}

.Tabsmenuberita2:hover{
  background-color: #0b524b;
}

.rowBerita{
  margin-top: 120px;
  width: 100px;
  height: 310px;
  background-color: white;
  border: 1px solid #E0E0E0;
  border-radius: 0px;
  
}

.Listpromo{
  
  width: 100%;
  margin-top: 100px;
}

.cardPromolist{
  margin-top:10px;
  width: 260px;
  height: 310px;
  background-color: white;
  border: 1px solid #E0E0E0;
  border-radius: 0px;
  

}

.detailbannerberita{
  position: absolute;
  top: 13px;
  width: 845px;
  height: 245px;
  background-color: #0b524b;
}



.contentbreadcrumb{
  position: absolute;
  top: 5px;
  left: 20px;
  font-size: 12px;
  color:#565656;
}


/* STYLE BARU */

.menuContainer{
background-color: white;
width: 100%;
height: auto;

}

.btn-menu-promo{
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    width: 142px;
    height: 40px;
    background-color: #ffffff;
    font-size: 14px;
    color:#4F4F4F;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
}

.btn-menu-promo:active{
      width: 142px;
      height: 40px;
      background-color: var(--primary);
      font-size: 14px;
      color: white;
      font-weight: bold;
      border: 1px solid var(--primary);
      border-radius: 5px;
}

.contentContainer{
background-color: white;
margin-top: 5px;
padding-left: 30px;
padding-right: 30px;
padding-bottom: 30px;
  
}


.listBerita{
  margin-top: 40px;
  margin-bottom: 20px;
  width: 282px;
  height:387px;
  background-color: white;
  border: 1px solid #E0E0E0;
  border-radius: 6px;
}

.listContainer{
  width: 90%;
  background-color: yellow;
}


.listBeritaLainnya{
  margin-bottom: 20px;
  width: 295px;
  height:auto;
  background-color: white;
  border: 1px solid #E0E0E0;
  border-radius:6px;
  
}

.cardbanner{

  width: 258px;
  height: 160px;
}

.cardnewsdate{
  margin-left: 15px;
  margin-top: 15px;
  font-size: 12px;
  color: #979797;
  line-height: 140%;

}

.cardnewsdatelainnya{
  margin-left: 15px;
  margin-top: 15px;
  font-size: 12px;
  color: #979797;
  line-height: 140%;

}

.cardnewsjudul{
  height: 45px;
  margin-left: 16px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #4F4F4F;
  line-height: 140%;

   /* limit text  */
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   overflow: hidden;
  
}

.cardnewsjudullainnya{
  height: 45px;
  margin-left: 16px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #4F4F4F;
  line-height: 140%;
  margin-right: 16px;
}

.cardnewsdetail{
  height: 40px;
  margin-left: 16px;
  width: 250px;
  font-size: 14px;
  color:#4F4F4F;
  line-height: 140%;
}

.cardnewsdetaillainnya{
  height: 40px;
  padding-bottom: 7px;
  margin-top:10px;
  width: auto;
  font-size: 14px;
  color:#4F4F4F;
  line-height: 140%;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 7px;
 
  /* limit text  */
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   overflow: hidden;
   height: 40px;
  

}

.cardbutton{
  margin-top: 37px;
  margin-bottom: 0px;
  padding-top: 5px;
  width: auto;
  height: 39px;
  font-size: 16px !important;
  border-radius: 0px 0px 6px 6px !important;
 
  }


.containerDetailBerita{
  width: 100%;
  height: auto;
  background-color: white;
}

.beritaBreadcrumb{
  padding-top: 10px;
  width: 100%;
  height: 50px;
  background-color: white;
  font-size: 14px;
  color: #565656; 
}

.contentDetail{
  width: 100%;
  height: auto;
  margin-top: 3px;
  background-color: white;
}

.contentjudul{
  padding-top: 20px;
  margin-left: 30px;
  font-size: 36px;
  font-weight: bold;
  line-height: 140%;
  color:#4F4F4F;
}

.contentdate{
  margin-left: 30px;
  padding-top: 3px;
  padding-bottom: 30px;
  font-size: 14px;
  color:#BDBDBD;
}

.contentdesk{ 
  padding: 25px 30px 40px 5px;
  font-size: 18px;
  color:#4F4F4F;
  text-align: left; 
}

.beritaLainnya{
  margin-top: 24px;
  padding-top: 10px;
  width: 100%;
  height: auto;
  background-color: white;
  
}

.containerImg{
  width: 100%;
  height: 300px;
}

.tab-news {
  background: #fff; 
}

.tab-news-col {
  margin:0 1px; 
  padding:16px; 
  font-size: 21px;
  font-weight: 500;
  text-align: center;
  color: #979797;
  
  
}

.tab-news-col.active {
  color:#4F4F4F;
  border-bottom: 2px solid var(--primary);
}

.tab-news-col.href:hover{
  text-decoration: none;
}

.beritaSlideContainer{

  padding-left: 50px;
  padding-right: 30px;
  padding-bottom: 40px;

}

.berita-lain-title {
  padding: 30px;
  font-size: 21px;
  line-height: 19px;
  margin-left: 20px;
}

.berita-tab {
  margin-left:-15px;
  margin-right:-15px;
}

.berita-tab li {
  text-align:center;
}

.emptyState{
  padding-left: 400px;
  padding-top: 40px;
} 
    
.imgContentNews{
  width: 280px;
  height: auto;

  
}

.imgContentNews img{
  cursor: pointer;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

}

.breadcrumbStyle{
  margin-top: 20px;
  padding-top: 0px;
}

.ShareButton{
  width: 70%;
  padding-left: 24px;
}
.berita-lain-img{
  height:210px; 
}
.berita-lain-img img{

  cursor: pointer;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

}

.berita-lihatsemua{
  text-align: right;
  padding: 25px 51px 10px 0;
}



@media (max-width: 575.98px) {
  .beritaSlideContainer{
    padding-right: 35px;
    padding-left: 35px;
    width: 100%;
  }

  .berita-lain-title {
    font-size: 18px;
    padding-right: 0px; 
    margin-left: -25px;
  }

  .emptyState{
    padding:50px;
  }  

  .listBerita{
    width: 100%;
    height: 120px;
    padding:12px;
    margin: 0px 0px 10px;
    box-sizing: border-box;
    border: 1px solid #D8D8D8;
  }

  .imgContentNews{
    width: 127px;
    
    }

  .contentContainer{
    background:none;
    padding:0px;
    margin-top: 0px;
  }


  .beritaBreadcrumb{
    padding-top: 5px;
  }
  .cardnewsjudul{

    height: 40px;
    font-size: 14px;
    font-weight: bold;
    width: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    padding-left: 20px;
    
  
  }

  .cardnewsdetaillainnya{
    margin-top: 0px;
    font-size: 14px;
    padding-right: 10px;
    width: auto;
    margin-left: 3px;

    margin-right: 16px;

  }

  .cardnewsdetail{
    margin-top: 0px;
    font-size: 14px;
    padding-right: 10px;
    width: 100%;
    height:40px;
     display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-left:10px;
    padding-left: 20px;

    /* limit text  */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    

  }

  .contentdesk{
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 14px;
    padding-left: 7px;
    padding-right: 7px;
  }

  .contentjudul{
    margin-left:0px;
    font-size: 20px;
   }
  .contentdate{
    font-size: 12px;
    padding-bottom: 15px;
    margin-left:0px;
  }

  .backButton a{
    position: absolute;
    background-color:#D8D8D8;
  }

  .ShareButton{
    padding-left: 0px;
    margin-left: 30%;
  }

  .berita-carousel{
  margin-left: -35px;
  width: auto;
  padding-left: 0px;
  margin-right: -35px;
}
.listBeritaLainnya{
  height:280px;  
  margin: 0 5px 20px 5px; 
  border: 1px solid #E0E0E0; 
  border-radius: 8px !important;

  width:auto;
}
.berita-lain-img{
  height:110px; 
}

.berita-lain-img img{

  cursor: pointer;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

}

.cardbutton{
  margin-top:12px;
  border-radius: 0px 0px 8px 8px !important;
 
  width:auto;
}

.cardnewsdatelainnya{
margin-left:10px;
padding-bottom: 7px;
}

.cardnewsjudullainnya{
  font-size: 14px;
    font-weight: bold;
    width: auto;
    margin-top: 0px;
    margin-bottom: 0px;
     display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    height: 40px;
    
  margin-left:10px;
  margin-right: 10px;
}

.berita-lihatsemua{
  padding-top: 7px;
  margin-right: 0px;
  margin-top: 20px;
  padding-right: 0px;
}

}