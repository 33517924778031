.store-container {
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap; 
    width:auto;
    margin:-11px;  
}

.store-product-container {
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap; 
    margin:-26px; 
}

.store-item { 
    /* flex: 1 1 156px;   */
    width: 180px;  
    margin: 11px;
} 

.store-info-card {
    /* width: 252px; 
    height: 252px; */
    margin-right:50px;
}   

.store-info-content {
    margin-top:0px;
    padding:20px ;
}

.store-info-title {
    font-weight: bold;
    font-size:24px;
    color:#000000;
}

.store-info-owner {
    font-size:17px;
    font-weight: bold;
    color:#000000;
    padding-bottom:15px;
} 

.store-info-desc {
    border:1px solid #fff;
}

.store-product-content {
    padding:0 15px;
}
 
/* medium device */
@media (max-width: 991.98px) {
    .store-container { 
        margin:-3px; 
    }
    .store-item {   
        margin: 3px;
    }  

    .store-info-card {
        /* width: 252px;  */
        margin-right:-15px;
        margin-bottom:16px;
    }

    .store-info-owner {
        padding-bottom:6px;
    }

    .store-info-content {
        margin-top:0;
        padding:19px 10px 0px 10px;
    }

    .store-info-title {
        font-size:.875rem;
    }

    .store-info-owner, .store-info-desc {
        font-size:.75rem;
    }
    
    .store-product { 
        margin-top:18px;
        background:#fff;
    } 
}

/* small device */
@media (max-width: 575.98px) { 
    .store-item {  
        width: 111px;   
    }   

    .store-info-desc{
        padding-bottom: 0px;
    }
}

/* khusus iphone 5 / se  */
@media (max-width: 320px) { 
    .store-container {  
        max-width:240px;
        margin:0 auto;    
    }   
}
 