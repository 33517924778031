.product-lain-card {
  background: #fff;
  padding: 24px;
}

.product-lain-title {
  font-size: 21px;
  margin-bottom: 1rem;
  /* font-weight: bold; */
}

@media (max-width: 575.98px) {
  .product-lain-title {
    margin-left: 0px;
  }

  .product-lain-card {
    padding: 0px;
  }
}
