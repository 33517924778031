.popular-store-card {
    background:#fff; 
}  

.popular-store-card .react-multi-carousel-item .store-item-card { 
    /* width:181px; */
    margin:5px; 
} 

.popular-store-card .react-multi-carousel-list {  
    margin-left:-5px; 
} 

.popular-store-title {
    font-size: 20px; 
    font-weight: bold;
    line-height: 140%;
    margin-bottom:1rem; 
    color: #4F4F4F;
    /* font-weight: bold; */
}

.popular-store-link-more {
    font-size:16px;
    font-weight: 500;
    line-height: 16px;
    color:#4F4F4F;
}  

@media (max-width: 575.98px) {
    .popular-store-title {
        margin-left: 0px;
    }

    .popular-store-card{
        padding-right: 0px;
    }

    .popular-store-link-more{
        padding-right: 20px;
    }
}