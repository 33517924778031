.product-terbaru-card {
    background:#fff;
    
}


.product-terbaru-title {
    font-size: 20px; 
    margin-bottom:1rem;
    font-weight: bold;
    line-height: 140%;
    color: #4F4F4F;
    }

.terbaru-link-more {
    font-weight: 500;
    font-size: 16px;
    color: #4F4F4F;
}

.react-multiple-carousel__arrow--right{
    right: calc(1% + 1px);
}

.react-multiple-carousel__arrow--left{
    left: calc(1% + 1px);
}

/* .react-multi-carousel-track

.react-multi-carousel-list{
    padding-left: 20px;
    padding-right: 20px;
} */

/* .react-multiple-carousel__arrow */

.product-terbaru-container {
    width: 100%;
    margin-left: 0px;
  }

  @media (max-width: 575.98px) {
      .product-terbaru-container{
          width: 100%;
          margin-left: 0px;
      }

      .product-terbaru-title{
          margin-left: 0px;
      }
      .terbaru-link-more{
          padding-right: 20px;
      }
  }