@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1024px;
  }
}
