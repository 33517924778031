.bodyContent {
  width: 100%;
  min-height: 100vh;
  background-color: white;
}

.body-color {
  width: 100%;
  background-color: #e5e5e5;
  min-height: 100vh;
}

.body-content {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  margin-top: 1px;
  margin-left: 15%;
}

.navbar-nav-icon4 {
  position: absolute;
  top: 12px;
  right: 65px;
}

.form-search {
  width: 100%;
  align-items: center;
}

.input-search {
  position: absolute;
  overflow: hidden;
  width: 450px;
  height: 28px;
  right: 580px;
  top: 7px;
  color: #fff;
  background-color: #fff;
  border: 0px solid #fff;
  border-radius: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 10px;
}

.text-input-label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.banner-slider {
  position: fixed;
  top: 70px;
  left: 270px;
  width: 60%;
  height: 150px;
  background-color: white;
}

.sliderImage {
  position: absolute;
  margin-top: 200px;
}

.container-left-right-menu {
  position: absolute;
  top: 100px;
  left: 6px;
  width: 97%;
  height: 300px;
  margin: auto;
}

.menu-left-right {
  width: 100%;

  background: #fff;
}

.left-menu {
  position: absolute;
  top: 250px;
  margin-left: 10px;
  width: 400px;
  height: 300px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
}

.pinmart {
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  color: black;
  top: 5px;
  left: 5px;
}

.pinmart-lihatsemua {
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  color: blue;
  top: 5px;
  left: 320px;
}

.right-menu {
  position: absolute;
  top: 250px;
  margin-left: 420px;
  width: 410px;
  height: 220px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
}

.tabMenu {
  font-size: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: black;

  title {
    color: black;
  }
}

.formGroupPulsa {
  position: absolute;
  width: 100%;
  top: 45px;
  left: 15px;
}

.formPulsa {
  position: absolute;
  overflow: hidden;
  width: 190px;
  height: 40px;
  top: 30px;
  color: #fff;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 10px;
}

.formpulsaLabel {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  color: #4f4f4f;
}

.formPulsa2 {
  position: absolute;
  overflow: hidden;
  left: 200px;
  width: 170px;
  height: 40px;
  top: 30px;
  color: #fff;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 10px;
}

.formpulsaLabel2 {
  position: absolute;
  top: 6px;
  left: 200px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  color: #4f4f4f;
}

.labelharga {
  position: absolute;
  top: 150px;
  left: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  color: #828282;
}

.jumlahHarga {
  position: absolute;
  top: 160px;
  left: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #4f4f4f;
}

.btnHarga {
  position: absolute;
  top: 160px;
  left: 220px;
  width: 153px;
  height: 46px;
  border-radius: 5px;
  border-color: var(--primary);
  background: var(--primary);

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: #fff;
}

.btnHarga:hover {
  background-color: #0b524b;
}

.btnHarga:focus {
  background: var(--primary);
}

.formTagihan {
  position: absolute;
  overflow: hidden;
  width: 90%;
  height: 40px;
  top: 30px;
  color: #fff;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 10px;
}

.btnHargaTagihan {
  position: absolute;
  top: 140px;
  left: 15px;
  width: 153px;
  height: 46px;
  border-radius: 5px;
  border-color: var(--primary);
  background: var(--primary);

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: #fff;
}

.btnHargaTagihan:hover {
  background-color: #0b524b;
}

.btnHargaTagihan:focus {
  background: var(--primary);
}
.labelharga2 {
  position: absolute;
  top: 140px;
  left: 270px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  color: #828282;
}

.jumlahHarga2 {
  position: absolute;
  top: 150px;
  left: 270px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #4f4f4f;
}

/* STYLE BARU */

.bodyContainer {
  width: 100%;
  min-height: 100vh;
  background-color: white;
}

.bannercontainer {
  width: 100%;
  margin-top: 25px;
}

.banner-item {
  margin-left: 10px;
}

.beranda-category-carousel .react-multi-carousel-item {
  min-height: 105px;
}

.pinmartJudul {
  margin-bottom: 1rem;
  font-size: 21px;
  color: #4f4f4f;
}
.pinmartContainer {
  margin-top: 25px;
  padding: 24px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 7.04033px;
}

.slideContainer {
  /* padding-left: 20px; */
  /* width: 95%; */
  height: auto;
}

.pinmartKotak {
  background: #ffffff;
  /* box-shadow: 0px 0px 2px 1px #E7EAEB, 1px -2px 3px 1px rgba(231, 234, 235, 0.5);
  border: 1px solid #E7EAEB;
  border-radius: 3.52017px; */
  padding-top: 0px;
  margin: 0 5px;
  text-align: center;
  cursor: pointer;
  width: 90.64px;
}

.pinmartKotak:hover {
  background-color: #f2f2f2;
  box-shadow: 0px 0px 5px 5px #f2f2f2;
}

.pinmartKotak img {
  margin-top: 8px;
}

.react-multi-carousel-item:first-child .pinmartKotak,
.react-multi-carousel-item:first-child .banner-item {
  margin-left: 0px;
  /* margin-right:0px; */
}

/* Custom jarak panah carousel */
.beranda-category-carousel .react-multiple-carousel__arrow--right {
  right: 0;
}
.beranda-category-carousel .react-multiple-carousel__arrow--left {
  left: 0;
}

.banner-carousel {
  position: relative;
  margin: 0px;
}

.banner-carousel .react-multi-carousel-dot-list {
  display: none;
}

/* Custom beranda carousel */

.beranda-carousel {
  max-width: 920px;
}
.beranda-carousel,
.beranda-carousel-choice {
  position: relative;
  margin: auto;
}
.beranda-carousel .react-multi-carousel-list,
.beranda-carousel-choice .react-multi-carousel-list {
  position: static;
}
.beranda-carousel .react-multiple-carousel__arrow::before,
.beranda-carousel-choice .react-multiple-carousel__arrow::before {
  content: "";
}
.beranda-carousel .react-multiple-carousel__arrow:focus,
.beranda-carousel-choice .react-multiple-carousel__arrow:focus,
.beranda-carousel .react-multiple-carousel__arrow:hover,
.beranda-carousel-choice .react-multiple-carousel__arrow:hover {
  border: none;
  background-color: none;
  outline: 0;
}
.beranda-carousel .react-multiple-carousel__arrow,
.beranda-carousel-choice .react-multiple-carousel__arrow {
  /* border:1px solid red;  */
  background: none;
  /* min-width:32px !important; */
  /* min-height:32px !important; */
}
.beranda-carousel .react-multiple-carousel__arrow--left,
.beranda-carousel-choice .react-multiple-carousel__arrow--left {
  left: -55px;
  background-image: url("./Images/carousel-arrow-left.png");
}
.beranda-carousel .react-multiple-carousel__arrow--right,
.beranda-carousel-choice .react-multiple-carousel__arrow--right {
  right: -55px;
  background-image: url("./Images/carousel-arrow-right.png");
}

.pinmartLink {
  padding: 8px 4px;
  font-size: 11.4405px;
  font-weight: bold;
  line-height: 13px;
  color: #565656;
  text-align: center;
}

.produkVirtual {
  padding-left: 10px;
  margin-top: 5px;
  margin-left: 50px;
  margin-bottom: 10px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #ffffff;
}

.produkVirtual:hover {
  background-color: #f2f2f2;
  box-shadow: 0px 0px 8px 8px #f2f2f2;
  border: 1px solid white;
}

.produkVirtualHref {
  align-content: center;
  text-align: center;
  margin-left: 60px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #565656;
}

.produkVirtualJudul {
  margin-top: 20px;
  padding-bottom: 20px;
  margin-left: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 21px;
  font-weight: bold;
  color: #4f4f4f;
}

.help-floating-button {
  position: fixed;
  bottom: 28px;
  right: 44px;
  z-index: 1001;
}

.product-choice-home {
  padding-top: 40px;
  padding-bottom: 40px;
}

.product-choice-home a {
  color: #fff;
}

.nearby-store-home {
  /* margin: 36px auto 64px auto; */
  margin: 36px -35px 64px -35px;
  padding-top: 25px;
  padding-bottom: 45px;
  background: var(--primary);
  border-radius: 20px;
  color: #fff;
}

.nearby-store-home a {
  color: #fff;
}

/* .nearby-store-home > div { 
} */

@media (max-width: 991.98px) {
  /* .beranda-carousel {
    max-width: 560px !important;
  } */

  .nearby-store-home {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 575.98px) {
  .pinmartContainer {
    padding: 0px;
    border: none;
    margin-left: -15px !important;
    margin-right: -15px !important;
    width: auto;
  }

  .beranda-carousel,
  .beranda-carousel-choice {
    margin-left: 0px;
    width: auto;
    padding-left: 0px;
    margin-right: -5px;
  }

  .banner-carousel {
    margin-bottom: 20px;
  }

  .banner-carousel .react-multi-carousel-dot-list {
    display: flex;
    bottom: -25px;
  }

  .banner-item {
    margin-left: 0px;
  }

  .pinmartKotak {
    width: auto;
  }

  .help-floating-button {
    bottom: 70px;
    right: 11px;
  }

  .product-choice-home {
    padding-top: 40px !important;
    padding-bottom: 15px !important;
  }

  .nearby-store-home {
    padding-left: 15px;
    padding-right: 15px;
  }
}
