.payment-page-title {
    font-size:24px;
    font-weight: bold; 
    margin-top:24px;
    margin-bottom:6px;
    margin-left: 40%;
}

.payment-card {
    background: white;
    margin: 8px 0px; 
    border-bottom: 3px solid rgba(224, 224, 224, 0.6);
    padding:16px;
    color: #4F4F4F;
    width: 60%;
}

.payment-card 
.title {
    font-weight: bold;
    font-size: 16px;  
}

.payment-card
.bank-list-item {
    padding-top:20px;
}

.btn-simpan {
    width: 234px;
    margin:24px 0;
}

.payment-small-note {
    padding-top:8px;
    font-size: 12px;
    color: #828282;
}

@media (max-width: 991.98px) {

.payment-card{
    width: 100%;
}

.payment-page-title{
    margin-left: 0px;
    text-align: center;
}
}