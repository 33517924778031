.product-popular-card {
    background:#fff; 
}

.product-popular-title {
    font-size: 20px; 
    margin-bottom:1rem; 
    font-weight: bold;
    color: #4F4F4F;
    
}

.popular-link-more {
    font-size:16px;
    color:#4F4F4F;

}

@media (max-width: 575.98px) {
.product-popular-title{
    margin-left: 0px;
}

.popular-link-more{
    padding-right: 20px;
}
}