.wallet-container {

    width: 837px;  
    margin: auto;
    margin-top: 48px;

}

.wallet-container
.wallet-header { 
    color: #fff;
    height:118px;
    position:relative;
    background-image: url(../../Images/walletbg.png);
  
    background-color: #C4C4C4;
    border-radius: 8px;
    padding: 40px 41px 0px 43px ;
}

.wallet-container
.wallet-content { 
    background: #fff; 
}

.wallet-content
.transaction-item 
.item-price { 
    text-align:right;
    font-size: 16px;
    line-height: 19px;
    color: #929292;
}

.wallet-content
.transaction-item 
.item-price-credit { 
    text-align:right;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    color: #058374;
}

.wallet-container 
.info-saldo {
    position:absolute;
    top:30%;
    left:18px;  
}

.wallet-container 
.btn-tambah-saldo {
    position:absolute;
    top:6px;
    right:6px;
    color: #fff;  

    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
}

.wallet-container
.wallet-saldo {

    font-weight: 500;
    font-size: 26.4px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 8px;
}

.wallet-container
.label-saldo {

    font-size: 12.7286px;
    line-height: 10px;
    font-weight: 500;
    color: #FFFFFF;
}

.wallet-container
.section-title { 
    margin-top: 33px;
    margin-left: 8%;
    font-weight: bold;
    padding:18px 18px;
    font-size: 20px;
    line-height: 140%;
    color: #4F4F4F;
    }

.wallet-content
.transaction-item { 
    line-height: 1.25rem; 
    font-size: 0.875rem;
    padding:18px 0px 18px 0px;
    border-bottom:1px solid #ccc;
    margin-left: 10%;
    width: 70%;
}
/* 
.wallet-content
.transaction-item:last-child { 
    border-bottom: none !important;
    background:red !important;
} */

.wallet-content
.transaction-item 
.item-title { 
    font-weight:bold;
}

.wallet-content
.transaction-item 
.item-date { 
    color:#666;
}

.wallet-topup-container {
    margin:auto 12%;
}


/* wallet topup */
.wallet-topup-container
.topup-nominal-card,
.wallet-topup-container
.topup-payment-card,
.wallet-topup-payment-container
.topup-payment-main-card,
.wallet-topup-payment-container
.topup-payment-bank-card {
    padding:18px;
    background:#fff;
}

.topup-nominal-card
.option-nominal { 
    margin-top:20px;
    /* width: 75%; */
    /* margin-left: 11%; */
} 

.topup-nominal-card
.option-nominal-item {
    border:1px solid #C4C4C4;
    border-radius: 3px;
    text-align: center;
    padding:8px 0;
    margin-bottom:10px;
    font-weight: 500;
    cursor: pointer;
    background-color: #F6F6F6;
} 

.topup-nominal-card
.input-container{
    margin-top: 54px;
    /* margin-left: 11%; */
}

.wallet-topup-container 
.topup-payment-card {
    margin-top:18px;
}

.wallet-topup-container 
.section-title {
    /* margin-left: 12%; */
    font-weight: 500;
    font-size: 20.5714px;
    line-height: 24px;
    color: #4F4F4F;
}

.topup-nominal-card 
.section-title,
.wallet-topup-payment-container 
.topup-payment-main-card
.section-title{
    font-size: 1.25rem;
}



/* Wallet Topup Payment */
.wallet-topup-payment-container 
.topup-payment-bank-card { 
    margin-top:18px;
} 
 
.topup-payment-bank-card
.bank-account-item {
    margin:18px 0;
    padding:0;
} 

.breadcrumbStyleWallet{
    margin-top: 48px;
    margin-left: 91px;
}

.label-input{
    font-size: 14px;
    line-height: 16px;
    color: #4F4F4F;
}

.btn-Close-topup{
  background-color: #FFFFFF;
  color:#828282;
  position: absolute;
  padding: 5px;
  padding-top: 0px;
  top: 35px;
  left: 85%;
  height: 20px;
}

.paymentRowStyle{
    /* margin-left: 10%; */
    margin-top: 20px;
    /* width: 90%; */
}

/* Small device */
@media (max-width: 767.98px) {

    .wallet-container{
        margin: auto;
        margin-top: 20px;
        width: 90%;
    }

    
    .wallet-topup-container {
        margin:auto;
    }

    .wallet-container
    .wallet-header,
    .wallet-container
    .wallet-content,
    .wallet-topup-container
    .topup-nominal-card,
    .wallet-topup-container
    .topup-payment-card, 
    .wallet-topup-payment-container
    .topup-payment-main-card, 
    .wallet-topup-payment-container
    .topup-payment-bank-card, 
    .fixed-bottom-nav {
        margin-left: -15px;
        margin-right: -15px;
    }

    .wallet-content
    .transaction-item 
    .item-price { 
        text-align:right !important;
    } 

    .breadcrumbStyleWallet{
        margin-left: 0px;
       
    }

    .wallet-content
    .transaction-item{
        margin-left: 0px;
        width: 100%;

    }
    
    .wallet-container
    .section-title{
    margin-left: -15px;
    }

    .wallet-topup-container 
    .section-title{
    margin-left: 0px;
}

.topup-nominal-card
.input-container{
margin-left: -10px;
}

.topup-nominal-card
.option-nominal { 

    margin-left: -10px;
    width: 100%;
}

.topup-payment-card{
    padding-bottom: 50px;
}

.paymentRowStyle{
    margin-left: -15px;
    width: 100%;
}

.paymentcolbtnstyle{
    padding-top: 20px;
}

    
}