
.search-card .search-suggestion {
    list-style-type: none;
    padding:12px 0;
    border:1px solid #ccc;
} 

.search-card .search-suggestion a {
    color:#5A5A5A;
    display:block;
    font-size:15px;
    padding:8px 25px; 
}

.search-card .search-suggestion img {
    /* height:21px; */
    width:36px;
    padding:0 10px;
}