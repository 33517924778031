.Token {
    text-align: center;
  }
  
  .Token {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Token-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .Token-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Token-link {
    color: #61dafb;
  }
  
  @keyframes Beranda-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  
    
  }

  .tokenContainer{
      width: 75%;
      max-height: 570px;
      margin-left: 171px;
      margin-top: 20px;
      border: 1px solid #C4C4C4;
      border-radius: 16px;
      padding: 34px;
      margin-bottom: 50px;
  }

  .tokenJudul{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #4F4F4F;
  }

  .labelToken{
    margin-top: 32px;
    font-size: 12px;
    line-height: 14px;
    color: #4F4F4F;
  }

  .nominalFont{
    font-size: 12px;
    line-height: 14px;
    color: #4F4F4F;
    margin-top: 35px;
  }

  .priceListContainerPrepaid{
    margin-top: 14px;
    width: 100%;
    max-height: 200px;
    overflow: scroll;
    padding-right: 50px;
  }

  .priceContainer{
    width: 100%;
    height: 45px;
    background: #F6F6F6;
    border: 1px solid #C4C4C4;
    border-radius: 3px;
    margin-bottom: 27px;
    padding-right: 0px;
    padding-left: 10px;
  }

  

  .priceContainer:hover{
    background: #FFFFFF;
    border: 1px solid #13A89E;
    border-radius: 3px;
    }

  .priceContainer:focus{
      background-color: #FFFFFF;
      border: 1px solid #13A89E;
      border-radius: 3px;
      box-shadow: none;
      
   
    }

   .priceContainerSelected{
    width: 100%;
    height: 45px;
    
    background: #FFFFFF !important;
    border: 1px solid #13A89E !important;
    border-radius: 3px !important;
    border-radius: 3px;
    margin-bottom: 27px;
    padding-right: 0px;
    padding-left: 10px;
      }

    
  .btnPriceContainer{
    width: 100%;
    padding-right: 10px;
  }
    
  .nameFont{
    position: absolute;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    color: #787878;
    text-align: left;
    padding-right: 5px;
    padding-left: 15px;
    
  }

  .priceFont{
    top: 15px;
    font-size: 16px;
    line-height: 14px;
    color: #000000;
    text-align: right;
   }

  .btnToken{

    margin-left: 65%;
    margin-top: 70px;
  }

  .btnplnClose{
  background-color: #FFFFFF;
  color:#828282;
  position: absolute;
  padding: 5px;
  padding-top: 0px;
  top: 60px;
  left: 80%;
  height: 20px;
  }

  .errorTextPLN{
    margin-left: 5px;
  }

  @media (max-width: 575.98px) {
  .tokenContainer{
    margin-left: 20px;
    width: 100%;
    margin-right: 20px;
  }

  .btnToken{
    margin-left: 0px;
  }

  .priceListContainerPrepaid{
    width: 100%;
    padding-right: 0px;
    
  }

  
  
  }