.OrderDigital {
    text-align: center;
  }
  
  .OrderDigital {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Beranda-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .OrderDigital-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .OrderDigital-link {
    color: #61dafb;
  }
  
  @keyframes Beranda-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  
    
  }
  .trBorder{
    border: 1px solid #FFFFFF;
  
  }

  .tablelist{
      border: none !important;
  }
  


.RincianJudul{
  margin-top: 29px;
  margin-left: 430px;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
}

.RincianpulsaListJudul{
    margin-top: 15px;
    font-size: 17px;
    line-height: 20px;
    color: #4F4F4F;
    padding-bottom: 7px;
    
  }
  
  .RincianpulsaListDesk{
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    color: #4F4F4F;
  
  }
  

.RincianPesananContainer{
  margin-top: 15px;
  width: 100%;
  min-height: 190px;
  background-color: #FFFFFF;
  border: 3px solid #f2f2f2;
}

.PaymentContainer{
  margin-top: 35px;
  width: 100%;
  min-height: 190px;
  background-color: #FFFFFF;
  border: 3px solid #f2f2f2;

}
.RincianPesananJudul{
  padding-bottom: 15px;
  margin-top: 28px;
  margin-left: 47px;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #4F4F4F;
}

.rincianPrice{
  margin-left: 40px;
  margin-top: 25px;
  font-size: 24px;
  line-height: 28px;
  color: #1D1D1D;
  font-weight: bold;
}

.btnStyle{
  padding-bottom: 0px;
  width: 100%;
}

.Pulsa-btn{
  margin-top: 20px;
  margin-left: 0px;
  width: 200px;
  height: 44px;
  font-size: 20px;
  }


.paymentLink{
  margin-left: 40px;
  margin-top: 20px;
  }

.ppob-confr-Container{
  width: 100%; 
  padding: 26px 28px 28px 28px;

}

.ppob-rincian-font{
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #4F4F4F;
  padding-bottom: 23px;
}

.ppob-label-font{
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  padding-bottom: 19px;
  
}

.ppob-ket-font{
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.ppob-hr{
  width: 100%;
  height: 5px;
  margin-top:4px;
  background-color:#E2E2E2;
  
}

.payment-note{
  padding-top:8px;
    font-size: 14px;
    color: #828282;
}

.payment-box{
  margin-bottom: 40px;
  margin-top: 20px;
}
@media (max-width: 575.98px) {
  .Pulsa-btn{
    width: 100%;
    margin-left: 0px;
  }

  .btnStyle{
    padding-bottom: 120px;
  }

  .pulsaListJudul{
    margin-left: 0px;
    margin-top: 10px;
  }

  .rincianPrice{
    margin-left: 45%;
    padding-bottom: 20px;
  }
  .PaymentContainer{
    margin-left: 0px;
    width: 100%;
    margin-top:10px ;
     }

  .paymentLink{
    margin-top: 15px;
    margin-left: 0px;
    padding-bottom: 10px;
  }

  .RincianPesananJudul{
    padding-bottom: 0px;
  }

  .payment-box{
    margin-bottom: 0px;
    padding-bottom: 0px ;
  }
}