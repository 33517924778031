/* .app-topnav {
  position:relative;
} */

.topnav-form {
  position: relative;
  margin-top: 10px;
}

.topnav-form input,
.topnav-form-mobile input {
  padding: 0 55px;
}

.topnav-search-icon {
  position: absolute;
  top: 10px;
  left: 19px;
  height: 18px;
  z-index: 1002;
}

.topnav-search-btn {
  position: absolute;
  top: 0px;
  right: 5px;
  z-index: 1002;
}

.topnav-nav a.nav-link {
  font-weight: bold;
  color: #fff !important;
}

.nav-link {
  margin: 0 8px;
}

.app-menu-cart {
  position: relative;
  display: inline;
}

.top-search-active {
  z-index: 1002;
}

.app-bg-dark {
  background: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 1001;
}

.cart-count {
  font-size: 9px;
  position: absolute;
  top: -5px;
  right: -10px;
  border-radius: 8px;
}

/* DROPDOWN MENU ANGGOTA */

.dropdown-anggota .dropdown-menu {
  right: 0 !important;
  left: auto !important;
}

.dropdown-anggota a.dropdown-item.active {
  background: #fff;
  color: #16181b;
}

.dropdown-anggota .dropdown-item {
  position: relative;
  display: block;
  background: #fff;
  border-bottom: 1px solid #ccc;
}

.dropdown-anggota .dropdown-item-kartu {
  padding-bottom: 33px;
}

.dropdown-anggota .dropdown-item > .dropdown-item-text {
  padding: 8px 8px 8px 44px;
}

.dropdown-anggota .dropdown-item > img {
  position: absolute;
  top: 12px;
  left: 24px;
  height: 21px;
}

.dropdown-anggota .dropdown-title {
  font-weight: bold;
  padding: 8px 20px;
}

.mobile-search-container {
  position: fixed;
  top: 10px;
  left: 2%;
  z-index: 1040;
  width: 96%;
  background-color: var(--primary);
}

.top-menu-cart {
  position: absolute;
  top: 10px;
  right: 55px;
  /* right:20px;  */
}

.top-menu-search {
  position: absolute;
  top: 10px;
  right: 10px;
  /* border:1px solid red;    */
  /* right:20px;  */
}

.topnav-form-mobile {
  position: relative;
  width: 100%;
  z-index: 1000;
}

.topnav-form {
  position: relative;
  min-width: 455px;
  margin-top: 0px;
}

.search-suggestion-web {
  position: fixed;
  background: #fff;
  z-index: 1002;
  list-style-type: none;
  padding: 12px 0;
  width: 463px;
  /* padding:8px 0; */
}

.search-suggestion-web li div.link {
  color: #5a5a5a;
  font-size: 15px;
  padding: 8px 25px;
  /* display:block; */
  cursor: pointer;
}

.search-suggestion-web li div.link:hover {
  text-decoration: none;
}

.search-suggestion-web img {
  /* height:21px; */
  width: 38px;
  /* height: 21px; */
  padding: 0 10px;
}

.bounceEffect {
  animation-duration: 2s;
  animation-iteration-count: initial;
  transform-origin: center;
  animation-name: bounce-1;
  animation-timing-function: ease-in-out;
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  5% {
    transform: translateY(5px);
  }
  10% {
    transform: translateY(-5px);
  }
  15% {
    transform: translateY(5px);
  }
  20% {
    transform: translateY(-5px);
  }
  25% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(0);
  }
  55% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(-5px);
  }
  65% {
    transform: translateY(5px);
  }
  70% {
    transform: translateY(-5px);
  }
  85% {
    transform: translateY(0);
  }
}

.cardKeanggotaan {
  margin-top: 10px;
  width: 296px;
  height: 162.44px;
  background-image: url(../../Images/bg-kartu-anggota.png);
}
.cardKeanggotaan2 {
  position: absolute;
  top: 85px;
  width: 293.29px;
  height: 121.83px;
  background-image: url(../../Images/cardVector.png);
}

.cardJudul {
  padding-top: 36px;
  margin-left: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 10.8293px;
  line-height: 13px;
  color: #ffffff;
}

.cardName {
  padding-top: 3px;
  margin-left: 16px;
  font-weight: bold;
  font-size: 18.0488px;
  line-height: 21px;
  color: #ffffff;
}

.cardNotelp {
  padding-top: 3px;
  margin-left: 16px;
  font-size: 12.6341px;
  line-height: 15px;
  color: #ffffff;
}

.cardEmail {
  padding-top: 40px;
  margin-left: 16px;
  font-size: 12.6341px;
  color: #ffffff;
  padding-bottom: 12px;
}

.cardbuttonKeanggotaan {
  position: absolute;
  margin-top: 33px;
  margin-left: 200px;
  padding-right: 14px;
  padding-top: 7px;
  width: 84.88px;
  height: 24.44px;
  background: #ffffff;
  border-radius: 4.5122px;

  font-weight: 500;
  font-size: 11px;
  line-height: 9px;
  color: var(--primary);
}

.cardbarcode {
  position: absolute;
  margin-top: 10px;
  margin-left: 235px;
  padding-left: 5px;
  padding-top: 5px;
  width: 51px;
  height: 51px;
  background-color: white;
  border-radius: 2.70732px;
}

.kartu-anggota {
  padding: 0 16px;
  border-radius: 8px;
  position: relative;
  min-width: 300px;
  min-height: 162px;
  background-image: url(../../Images/bg-top-kartu-anggota.png);
  background-position: top left;
  background-repeat: no-repeat;

  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 1px 2px 5px #ccc;
}

.kartu-anggota-title {
  padding-top: 36px;

  font-size: 11px;
  line-height: 13px;
  color: #5a5a5a;
}

.kartu-anggota-barcode {
  position: absolute;
  padding: 5px;
  top: 14px;
  right: 14px;
  width: 51px;
  height: 51px;
  /* background-color: white; */
  border-radius: 3px;
}

.kartu-anggota-name {
  padding-top: 3px;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #5a5a5a;
}

.kartu-anggota-phone {
  padding-top: 3px;
  font-size: 12px;
  line-height: 15px;
  color: #5a5a5a;
}

.kartu-anggota-email {
  position: absolute;
  bottom: 12px;
  font-size: 12px;
  color: #5a5a5a;
}

.kartu-anggota-btn {
  position: absolute;
  right: 14px;
  bottom: 14px;
  padding: 4px 14px;
  width: 85px;
  height: 24px !important;
  border-radius: 5px;

  font-weight: 500;
  font-size: 11px;
  /* color: var(--primary); */
}

@media (max-width: 991.98px) {
  .topnav-nav {
    display: none;
  }

  .search-suggestion-web {
    width: 100%;
  }

  .cardKeanggotaan {
    margin-left: 8px;
  }
}

/*  */
