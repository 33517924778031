/* large device */
@media(min-width: 992px) {
    .order-carousel {
        justify-content: center;
    }
}

.order-item-card {
    background:#fff;
}

.order-payment-card,
.detail-order-total-card { 
    padding: 16px;
    background: #fff;
}

.detail-order-total-card {
    margin-top:8px;
}

/* Order List */

.order-page-title {
    font-size:20px;
    font-weight: bold;
    margin-top:29px;
    text-align:center;
}

.order-filter-text {
    margin:18px 0;
    font-size:1.2rem;
} 

.order-carousel-item {
    height: 50px;  
    border: 1px solid #565656; 
    border-radius: 100px; 
    font-size: .875rem;
    padding:8px;
    color:#565656;
    margin:0 8px; 
    text-align:center;
    line-height: .875rem;
    cursor:pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

.order-carousel-item:hover {
    background:var(--primary);
    color:#fff;
}

.order-carousel-item.active {
    background:var(--primary);
    color:#fff;
}

.order-carousel .react-multiple-carousel__arrow--left {
    left:0px; 
    position:absolute;
}

.order-carousel .react-multiple-carousel__arrow--right {
    right:0px; 
    position:absolute;
}


/* justify-content */

.order-item-card {
    border-bottom:1px solid #f3f3f3;
    margin-top:41px;
}

.order-item-header {
    padding:22px 25px;
    font-size:18px;
    font-weight: bold;
}


.order-item-header .order-payment-status {
    text-align: right;
}

.order-item-body {
    color:#4F4F4F; 
}

.order-item-product {
    padding:0 12px;
}



.order-item-body .order-product-img { 
    margin:0 12px; 
    position:relative;
}

.order-product-img .order-discount-img { 
    position:absolute;
    top:0px;
    right:0px;
    background:url('./../../../Images/shape-discount-list.png') 0% 0% no-repeat;
    width:33px;
    height: 55px;
    color:#fff;
    font-size: 12px;
    padding:5px 0px;
    text-align: center;
    font-weight: bold; 
}

.order-item-body .order-product-title { 
    color:#4F4F4F; 
    font-size:18px; 
}

.order-item-body .order-product-unit { 
    color:#828282; 
    font-size:14px; 
}

.order-item-body .order-product-qty { 
    font-size:.875rem;
}

.order-item-body .order-row-price {
    padding-top: 26px;
}

.order-item-body .order-product-price { 
    font-size:.875rem;
    font-weight: bold;
}

.order-item-body .order-product-price .order-discount-price {
    color: red;
    padding-right:16px;
}

.order-item-body .order-product-price .order-strip-price {
    text-decoration: line-through;
    display:inline-block; 
}

.order-item-body .order-product-total-price { 
    font-size:15px;
    font-weight: bold;
    text-align:right;
}

.order-item-body .product-summary-label,
.order-item-body .product-summary-price { 
    text-align:right;
}

.order-item-body .product-summary-price, 
.order-item-body .product-summary-price {
    padding-right:40px;
}

.order-item-body .product-summary-price {
    font-size:17px;
    
}

.order-collapse {
    margin-top:16px;
    position:relative;
    background:#C4C4C4;
    height:2px;
    text-align:center;
}

.order-collapse .order-collase-text { 
    position:absolute;
    max-width:170px;
    top:-17px;
    left:50%;
    /* margin-left:-85px; */
    z-index:9;
    color:#4F4F4F;
    /* border:1px solid red; */
 
    padding:5px; 
    background:#fff;  
}

.order-collapse .btn-link, 
.order-collapse .btn-link:hover { 
    text-decoration: none !important;
    font-weight: bold;
}

.order-item-footer .order-col-footer {
    padding:15px 36px; 
}

.order-footer-content {
    font-weight: bold;
    padding-top: 8px;
}

.order-item-line {
    background:#E7E7E7;
    height:3px;
}

.order-item-vline {
    border-left:3px solid #E7E7E7;
    border-right:3px solid #E7E7E7; 
}

.order-item-card .product-price {
    font-weight: bold;
    font-size:18px;
}

.order-item-card .order-date {
    color: #666;
    font-size:14px;
    padding:12px 0;
}

.order-item-card .btn-detail {
    margin-top:10px;
    font-size:.875rem;
    padding:5px;
}

.order-item-card .product-product { 
    font-size:.875rem;
    padding:5px 0;

    /* limit text */
    display: -webkit-box;
    -webkit-line-clamp: 1; /* jumlah baris yang ingin di limit*/
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.order-completed-card {
    text-align: center;
}

.order-completed-card > .section-img {
    margin-top:60px; 
}

.order-completed-card > .section-title {
    margin-top:53px;
    font-weight: bold;
}

.order-completed-card > .section-info { 
    width: 328px; 
    white-space: pre-wrap;      /* CSS3 */   
    white-space: -moz-pre-wrap; /* Firefox */    
    white-space: -pre-wrap;     /* Opera <7 */   
    white-space: -o-pre-wrap;   /* Opera 7 */    
    word-wrap: break-word; 
}

.order-completed-card > .section-btn { 
    margin-top: 30px; 
}

.order-payment-card
.order-number {
    font-weight: bold;
    text-align: right;
}

.order-payment-card
.section-title {
    color: #4F4F4F; 
    text-align: center;
    font-weight: normal;
    margin-top:24px;
} 

.order-payment-card
.line {
    margin:24px 0;
    border-bottom:1px solid #f2f2f2; 
    height:1px
} 

.order-payment-card
.order-price {
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    color: #4F4F4F;
}

.order-payment-card
.info-transfer {
    background: #F2F2F2;
    font-size:14px;
    max-width: 469px;
    padding: 10px;
    text-align: center;
    margin:auto;  
}

.order-payment-card
.transfer-deadline { 
    text-align:center; 
    font-size:20px; 
    font-weight:bold; 
}

.order-payment-card
.account-number {
    font-weight:bold;
} 

.order-payment-card
.info-detail-pembayaran {
    background: #f2f2f2;
    color:#828282;
    padding: 16px;
} 


.order-payment-card
.info-detail-pembayaran
.item-price {
    font-weight: bold;
    text-align:right;
    color: #4F4F4F;
}


.order-payment-card 
.section-title-detail { 
    font-weight: bold;
    font-size: 18px;
    padding:16px 0;
} 

.order-payment-card 
.btn-mart {
    margin-top:24px;
}


/* order detail */
.detail-order-product-card, 
.detail-order-delivery-card,
.detail-order-payment-card {
    background: white;
    border: 1px solid #ccc;
    margin: 8px 0px; 
    border-radius: 3px;
    padding:16px;
}

.section-title {
    font-weight: bold;
} 

/* address list card */

.detail-order-delivery-card {
    position: relative;
}

.detail-order-delivery-card
.delivery-title {
    padding-bottom:16px;
    font-weight: bold;
}

.detail-order-delivery-card
.address-label {
    font-weight: bold;
    color:#4F4F4F;  
}

.detail-order-delivery-card
.address-recipient { 
    color:#4F4F4F;
}

.detail-order-delivery-card
.address-address { 
    font-size:14px;
    color:#BDBDBD;
} 

.detail-order-info-pembayaran 
.card-body{ 
    padding:0px;
} 

.product-list-card {
    margin-top:16px;
}

.product-list-card
.list-product-name {
    font-weight: bold;
    color:#4F4F4F;
    font-size:.875rem;
}

.product-list-card
.list-product-unit { 
    font-size:.875rem;
    color:#828282;
} 

.product-list-card
.list-product-price,
.product-list-card
.list-product-total-price,
.product-list-card
.list-product-discount-price {
    font-weight: bold; 
}

.product-list-card
.list-product-price {
    color:#000;
}

.product-list-card
.list-product-total-price
{ 
    color:var(--primary);
}

.product-list-card
.list-product-discount-price { 
    color:red;
}

.product-list-card
.list-product-strip-price {  
    text-decoration: line-through;
    font-size: .75rem;
    display:inline-block;
}

.product-list-card
.list-product-qty {  
    text-align: right; 
}

.detail-order-total-card 
.total-price {
    text-align:center;
    font-size:24px;
    font-weight: bold;
    padding:8px 0;
}

.detail-order-total-card 
.m-total-price { 
    font-size:1rem;
    font-weight: bold; 
}

.detail-order-total-card 
.label-total-bayar {  
    font-weight: bold; 
    color: var(--primary);
}


.detail-order-total-card 
.badge-status,
.order-item-card 
.badge-status { 
    padding:.75rem; 
    color: #fff;
    border-radius:10rem;
    text-align: center;
    font-size: .75rem;
    line-height: 1rem;
    display:inline-block;
}

.tab-order {
    margin-left:-15px;
    margin-right:-15px;
}

.qrisImg{
   margin: 0px auto auto 5%;
    align-items:center;
}

.qrisNoteBold{
    margin-top: 21px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22.4px;
    text-align: center;
    color: #4f4f4f;
}

.qrisNote{
    font-weight: normal;
    font-size: 16px;
    line-height: 22.4px;
    text-align: center;
    color: #4f4f4f;

}

/* Small And Medium devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) { 

    .detail-order-total-card 
    .badge-status,
    .order-item-card 
    .badge-status { 
        padding:.3rem .6rem !important;  
    }

    .order-item-card .order-date {
        text-align:right;
    } 

    .order-item-body .order-product-img { 
        margin:0; 
    }
    .order-item-body .order-row-price { 
        padding-top:0;
    }
    .order-item-body .order-product-total-price {
        padding-top:8px;
        padding-right:13px;
        /* text-align:left; */
    }

    .order-item-header .order-product-date {  
        font-size:.875rem; 
    }
    
    .order-item-header .order-payment-status { 
        text-align:left;
        font-size:.875rem; 
    } 

    .order-item-body .order-product-price .order-strip-price { 
        display:block; 
    }
    .qrisImg{
        margin: 0px;
        
    }

    /* .order-item-body .order-product-title {  
        font-size:1rem; 
    } */
    
}

