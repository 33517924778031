.login-logo { 
    position: absolute;
    height: 84px;
    width: 250px;
    left: 50%;
    top: 50%;
    margin-left: -125px;
    margin-top: -42px;  

    font-size: 1.25rem;
    text-align:center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #fff;
}
    
.login-bg-pattern { 
    background-image: url('./../../Images/login-pattern.png'); 
    background-repeat: repeat;
    background-position: center; 
    /* border:1px solid red; */
    height: 100vh;
    opacity:0.3;
}
  
.login-left-box { 
    background-color:  var(--primary); 
    position:relative;
} 
    
.login-form-wrapper {  
    padding: 50px 55px;
    height:100vh;
    overflow:auto;
} 

.login-green-logo { 
    text-align: center; 
}

.login-green-logo img {
    width:220px;
}
  
.login-more {
    width: 50%; 
} 
    
.login-text-welcome{   
    margin-top:37px;
    margin-bottom:20px;
    font-weight: bold;
    font-size: 1.5rem;
    color: #4F4F4F;
}   

.login-form-group .form-label {
    font-size: .875rem;
}

.login-text-forgot {
    text-align: right;
    font-size: .875rem;
    color:var(--primary);
}

.login-btn {
    margin-top:32px;
}

.login-text-help {
    padding-top:48px;
    padding-bottom: 16px;
}

.login-text-notregister { 
    padding:24px 0;
    font-size: .875rem;
    text-align: center;
}

.login-text-notregister a {
    font-weight: bold; 
    color:var(--primary);
}

.login-message-error {
    font-size:.875rem; 
}

.contact100-form-checkbox {
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 35px;
}

.input-checkbox100 {
    display: none;
}
  
/* Register */
.register-wrapper {
    background:#fff;
    padding:15px;
}

.register-form {
    width:100%;
}

.register-green-logo {
    margin-top:38px;
    text-align: center;
}

.register-green-logo img {
    width:238px;
}

.login-form-wrapper .eye-icon,
.register-form .eye-icon {
    position: absolute;
    top:38px;
    right:10px;
}

.forgotText{
    padding-bottom: 34px;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}

.login-text-notforgot { 
    color: #828282;
    padding:24px 0;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
}

.login-text-notforgot a {
    font-weight: bold; 
    color:var(--primary);
}

.forgot-btn {
   margin-top:15px;
   border-radius: 5px;
}

.welcome-mesage-Container{
    margin-top: 250px;
    margin-left: 10px;

}

.welcome-message-text-bold{
    margin-top:37px;
    font-weight: bold;
    font-size: 40px;
    color: #4F4F4F;

}
.welcome-message-text{
    font-size: 17.5px;
    line-height: 21px;
    color: #4F4F4F;
    margin-top: 8px;
   }



@media (min-width: 992px) { 
    .register-wrapper {
        margin-top:15px;
    }

    .register-form {
        width:400px;
        margin:auto;
    }
    
}