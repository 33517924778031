.delivery-product-card {
    /* border-radius: 3px; */
    /* border: 1px solid #ccc; */ 
    margin-bottom:20px;
}

.delivery-product-item {
    margin-bottom:2px; 
    background: white; 
    padding:16px 20px;
    border-bottom: 2px solid rgba(224, 224, 224, 0.6);

}

.delivery-product-item
.delivery-product-content { 
    padding-left:17px;
}

.delivery-product-item
.list-product-name { 
    color:#4F4F4F;
    font-size:16px;
}

.delivery-product-item
.list-product-unit {  
    color: #828282;
    font-size:16px;
    line-height: 19px;
} 

.delivery-product-item
.list-product-price {
    font-weight: bold;
    color: var(--primary);
    font-size:  16px;
}

.delivery-product-item
.list-product-price-strip{
    margin-left: 5px;
    text-decoration: line-through;
    display:inline-block; 
}

.delivery-product-item
.list-product-total-price {
    font-weight: bold;
    color:#4F4F4F; 
    font-size:18px;
}

.delivery-product-item
.list-product-qty-confirm {
    font-size: 16px;
    color: #828282;
    line-height: 19px;
}

.deliveryRowStyle{
    margin-top: 12px;
    width: 90%;
    margin-left: 5%;
}

.hr-line{
    margin-top: 15px;
    width: 100%;
    height: 1px;
    background: rgba(224, 224, 224, 0.6);
}

.RingkasanBelanjaCard{
    background: white;
    margin-bottom: 20px; 
    padding:16px 20px 16px 45px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 1px 0px 3px -1px rgba(193, 189, 189, 0.25);
    border-radius: 8px;
    /* border: 1px solid #ccc; */

}

.Confr-Card{
    margin-bottom: 20px;
    padding: 16px 20px 16px 45px;
    border-bottom: 2px solid rgba(224, 224, 224, 0.6);


}

.address-phone-number{
    padding-top: 2px;
    font-size: 16px;
    line-height: 19px;
    color: #4F4F4F;
}

.deliveryFont{
    margin-top: 4px;
    font-size: 16px;
    line-height: 19px;
    color: #4F4F4F;

}

.discount-img{
    position:absolute;
    top:0px;
    right:0px;
    background:url('./../../../Images/shape-discount-list.png') 0% 0% no-repeat;
    width:30px;
    height: 55px;
    color:#fff;
    font-size: 12px;
    padding:5px 0px;
    text-align: center;
    font-weight: bold;
}

@media (max-width: 575.98px) {
   .deliveryRowStyle{
       margin-left: 0px;
       padding-left: 0px;
   }

   .Confr-Card{
       padding: 16px 20px 16px 0px;
   }
   .delivery-product-item{
       padding-left: 0px;
   }
}