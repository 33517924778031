.Pulsa {
    text-align: center;
  }
  
  .Pulsa {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Beranda-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .Pulsa-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Pulsa-link {
    color: #61dafb;
  }
  
  @keyframes Beranda-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  
    
  }

.RowPulsa{
  width: 80%;
  margin-left: 10%;
}
.pulsaContainer{
    margin-top: 20px;
    padding-top: 38px;
    width: 100%;
    height: 268px;
    background: #FFFFFF;
    border: 3px solid #f2f2f2;
}

.pulsaJudul{
    margin-left: 50px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
}

.form-pulsa{
    margin-top: 29px;
    margin-left: 50px;
    width: 70%;
}

.pulsaLabel-form{
    font-size: 12px;
    line-height: 14px;
    color:#4F4F4F;

}

.pulsaTabsContainer{
    margin-top: 62px;
    padding-left: 0px;
    width: 100%;
    border: 2px solid #f2f2f2;
    padding-right: 0px;
    
    background-color: #FFFFFF;
    
}

  .pulsa-custom-tab{
    width: 95%;
    height: 66px;
    margin-left: 15px;
    background:#FFFFFF;  
    padding:0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  } 

  
  /* .pulsa-custom-tab Nav.Item{
    margin-left: -15px;
  } */

  

  .pulsa-custom-tab-Nav-Item-active{
    display:inline-block; 
    width: 100%;
    border-bottom: 3px solid var(--primary);
    }

    .pulsa-custom-tab-Nav-Item{
      display:inline-block; 
      width: 100%;
      /* padding:10px 5px 10px 50px; 
      /* border-bottom: 7px solid var(--secondary); */
     

    }
    


  .pulsa-custom-tab-Nav-Link{
    
    font-size: 24px;
    line-height: 28px;
    color: #4F4F4F;
    font-weight: 500;
    margin-top: 8px;
    margin-left: 80px;
   
  }

  /* .pulsa-custom-tab-Nav-Link :active{
    
    border-bottom: 7px solid var(--primary);

  } */

  
   

  /* [grid] letakkan .active di col yang active*/
  .pulsa-custom-tab li.active {
    border-bottom: 7px solid var(--primary);
  }
  
  .pulsa-custom-tab li.active a {   
    color:#4F4F4F;
  }
  
  .pulsa-custom-tab Nav.Link {    
    font-size:21px; 
    color:#979797;  
    font-weight: bold;
  }
  
  .custom-tab a:hover {
    text-decoration: none;
  }
  
  
.pulsaListContainer{
  width: 100%;
  border: none;
  
  }

.listContainer{
  margin-top: 10px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #FFFFFF;
  width: 100%;
  height: 450px;
  overflow: scroll;
 
}

.trBorder{
  border: 1px solid #E7E7E7;
  height: 62px;
  
}

.dataListJudul{
  font-size: 14px;
  line-height: 18px;
  color: #1D1D1D;
  font-weight: 500;
  padding-bottom: 5px;
  
}
.pulsaListJudul{
  margin-top: 0px;
  
  font-size: 16px;
  line-height: 18px;
  color: #1D1D1D;
  font-weight: bold;
  
}
.pulsaListDesk{
  font-size: 14px;
  line-height: 18px;
  color: #565656;
  

}

.pulsaPrice{
  margin-top: 0px;
  padding-left: 100px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #008577;
  
}

.dataPrice{
  margin-top: 0px;
  padding-left: 100px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #008577;
  
}

.btn-Pulsa{
  width: 200px;
  height: 44px;
  font-size: 20px;
  
}

.RincianJudul{
  margin-top: 29px;
  margin-left: 430px;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
}

.RincianContainer{
  margin-top: 35px;
  width: 90%;
  height: 190px;
  background-color: #FFFFFF;
}

.RincianPesananJudul{
  margin-top: 28px;
  margin-left: 47px;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #4F4F4F;
}

.rincianPrice{
  margin-left: 40px;
  margin-top: 70px;
  font-size: 24px;
  line-height: 28px;
  color: #1D1D1D;
  font-weight: bold;
}
.btnStylePulsa{
  margin-top: 20px;
  padding-left: 370px;
  padding-bottom: 50px;
}

.providerResult{
  margin-left: 40%;
  margin-top: -20px;
}
.formPulsa{
  padding-bottom: 0px !important;
}

.table th, .table td{
  vertical-align: middle !important;
}

.btnClose{
  background-color: #FFFFFF;
  color:#828282;
  position: relative;
  padding: 5px;
  padding-top: 0px;
  top: -35px;
  left: 80%;
  height: 20px;
}

.errorText{
  margin-left: 50px;
}

.trBorder td{
  vertical-align: middle !important;
}

@media (max-width: 575.98px) {
  .RowPulsa{
    width: 100%;
    margin-left: 0px;
  }
  
  .listContainer{
    height: 450px;
    overflow: scroll;
    margin-bottom: 60px;
    
  }

  .providerResult{
    margin-left: 20%;
  }

  .pulsaTabsContainer{
    margin-top: 10px;
    padding-right: 7px;
    
  }

  .pulsaContainer{
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    
  }

  .pulsa-custom-tab{
    width: 93%;
  }

  .form-pulsa{
    width: 100%;
  }
  .btn-Pulsa{
  width: 100%;
  }

  .pulsa-custom-tab-Nav-Link{
    margin-left: 15%;
    }

    .pulsaJudul,
    .form-pulsa{
      margin-left: 0px;
    }
  .errorText{
    margin-left: 0px;
  }

  .pulsaListJudul{
    margin-top: 13px;
    margin-right: 0px;
  }

  .pulsaPrice{
    margin-top: 13px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .dataPrice{
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
  }

  .btnClose{
    top: -35px;

  }

  .trBorder td{
    vertical-align: top !important;
  }
}