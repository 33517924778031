.cart-page-title {
    color: #4F4F4F;
    font-size:24px;
    margin-top:29px;
    text-align:center;
    font-weight: 500;
}

.cart-item-card {
    background: white;
    margin-bottom: 20px; 
    padding:16px; 
    position:relative;
    border-bottom: 3px solid #E0E0E0;
    /* border-radius: 3px; */
}

.cart-item-card 
.product-title a {
    font-weight: 900;
    line-height: 16px;
    font-size: 16px;
    color:#4F4F4F !important;
}

.cart-item-card 
.product-price , 
.cart-item-card 
.product-total-price-mobile { 
    font-weight: bold;
    color: var(--primary);
}

.cart-item-card
.product-total-price{
    color:#4F4F4F;
    line-height: 21px;
    font-size: 18px;
    font-weight: bold;
}

.cart-item-card 
.product-price {   
    padding-right: 8px;
}

.cart-item-card 
.product-price-discount {   
    color: #FD0808;
    font-weight: bold;  
}

.cart-item-card 
.product-price-mobile {  
    padding-top:8px;
    padding-right:4px; 
    font-size:.875rem;
}

.cart-item-card 
.product-strip-price { 
    font-size:.875rem; 
    text-decoration: line-through; 
    display: inline-block;
}

.cart-item-card 
.product-total-price {  
    text-align:right; 
    font-size:18px;
}

.cart-item-card 
.product-unit {  
    color:#828282;
    font-weight: normal;
    font-size: 13px;
    padding-top:0px;
}

.cart-item-card 
input.form-control {  
    padding:1px;
}

.cart-item-card 
.btn-delete {  
    color:#828282;
    margin-left:-5px; 
}

.cart-item-card 
.btn-text-delete {  
    color:#828282;
    position: absolute;
    bottom:20px;
    right:12px;  
}

.cart-item-card 
.product-total-price-mobile {   
    position: absolute;
    bottom:0px;
    right:8px;
}

.cart-item-card 
.discount-image {
    position:absolute;
    top:0px;
    right:0px;
    background:url('./../../../Images/shape-discount-list.png') 0% 0% no-repeat;
    width:30px;
    height: 55px;
    color:#fff;
    font-size: 12px;
    padding:5px 0px;
    text-align: center;
    font-weight: bold;
}

.cart-note {
    padding-top:16px;
}

.cart-note
.cart-total-price {
    text-align:right;
    color:#4F4F4F;
    font-size:18px;
    margin-bottom:19px;
    font-weight: bold;
}

.cart-note
.cart-total-item { 
    text-align:right;
    color:#565656;
    font-size:12px;
}

.empty-cart {
    text-align:center;
}

.empty-cart > img {
    margin-top: 48px;
    margin-bottom: 34px;
}

.empty-cart > .empty-title {
    font-weight: bold;
}

.empty-cart > .btn {
    margin-top: 16px;
}

.product-summary-bottom {
    position:fixed;
    bottom:0px;
    left:0px;
    width:100%;
    padding:14px;
    z-index: 1001;
    background:#fff; 
}

.product-summary-bottom .label-price {
    font-weight:bold;
}

.product-summary-bottom .label-total {
    font-weight:bold;
    color:var(--primary);
}

.product-summary-bottom input.form-control {
    padding:1px;
}

.img-card{
    width: 100%;
    padding: 20px;
}


  .btnPreordercart{
    border-radius: 5.96264px;
    width: 70px;
    height: 16px;
    font-size: 10px;
    color:#FF0000;
    font-weight: bold;
    padding-bottom: 17px;
    margin-left: 0px;
    border: 1.29545px solid #FF0000;
    text-align:center;
    
}
  .btn-madetoorder-cart{
    display:inline-block;
    border-radius: 6px; 
    font-size: 10px;
    color:#001580;
    font-weight: bold;
    padding: 2px 8px;
    margin-left: 0px; 
    border: 2px solid #001580;
    text-align:center;
    background:#ECEFFF;
    
}
  


/* Special case for small device */
@media (max-width: 767.98px) { 
    .cart-item-card {
        margin:1px 0;
    }

    .cart-item-card 
    .product-total-price {  
        font-size:.875rem;
    }

    .cart-page-title {
        margin-bottom:16px;
    }
}