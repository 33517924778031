.choice-box {
  display: flex;
  flex-wrap: wrap;
}
.choice-box .choice-info {
  width: 199px;
}
.choice-info .choice-info-title {
  font-size: 38px;
  line-height: 100%;
}
.choice-info .choice-info-desc {
  font-size: 22px;
  padding-top: 14px;
  padding-right: 25px;
  line-height: 110%;
}
.choice-box .choice-product {
  max-width: 795px;
  width: 100%;
  position: relative;
}

.choice-link-more {
  font-weight: 500;
  font-size: 16px;
  color: #4f4f4f;
}

.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
}

.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px);
}

/* .react-multi-carousel-track

.react-multi-carousel-list{
    padding-left: 20px;
    padding-right: 20px;
} */

/* .react-multiple-carousel__arrow */

.product-choice-container {
  width: 100%;
  margin-left: 0px;
}

@media (max-width: 991.98px) {
  .choice-box .choice-info {
    width: 100%;
    margin-bottom: 22px;
  }
  .choice-box .choice-product {
    width: 100% !important;
  }
}
@media (max-width: 575.98px) {
  .product-choice-container {
    width: 100% !important;
  }

  .product-choice-title {
    margin-left: 0px;
  }
  .choice-link-more {
    padding-right: 20px;
  }
  .choice-box .choice-product {
    width: 100% !important;
    position: relative;
  }
  .choice-info .choice-info-title {
    font-size: 32px;
  }
  .choice-info .choice-info-desc {
    font-size: 18px;
  }
}
