.courier-page-title {
    font-size:24px;
    font-weight: bold; 
    margin-top:29px; 
    color: #4F4F4F;
    text-align: center;
}

.courier-card {
    background: white; 
    padding: 25px 45px;  
    margin-bottom:28px;
    cursor: pointer;
    border-bottom: 3px solid rgba(224, 224, 224, 0.6); 
}

.courier-card.active {
    border:1px solid var(--primary);
}
 
.courier-card.active .courier-info {
    margin-bottom:16px;
}

.courier-card .courier-name {
    font-weight: bold;
    font-size: 20px;
}

.courier-card .courier-image {
    position: absolute;
    top:10px;
    right:5px; 
}

.courier-card .courier-image img {
    height: 64px;
} 

.courier-card .courier-service {
    font-size:18px;
    /* max-height:50px;  */
    padding-left:15px;
    margin-bottom:13px; 
    margin-top: 25px;
    width:60%;
}

.courier-card .service-name { 
    padding-left:24px;
    color:#4F4F4F;
}

.courier-card .service-price {
    font-weight:bold;
}

.courier-card .service-etd {
    font-size:.875rem;  
    color: #4F4F4F;
}

.courier-info-layanan {
    background:#FFF8BD;
    padding:16px;
    border-radius:8px;
    width:60%;
}

.btn-simpan {
    width: 234px;
    margin:24px 0;
} 

.term-wrapper {
    padding: 50px 75px;
}

@media(max-width: 575.98px) {

    .courier-card {
        background: white; 
        padding: 20px;  
        margin-bottom:28px;
        cursor: pointer;
        width: 100%;
    }

    .courier-card .courier-name { 
        font-size: 1rem; 
    }
    
    .courier-card .courier-image {
        position: absolute;
        top:10px;
        right:20px;
    }
    
    .courier-card .courier-image img {
        height: 48px;
    } 

    .courier-card .service-name { 
        font-size: .875rem; 
    }

    .courier-card .courier-service { 
        width:100%; 
    }

    .courier-card .service-price { 
        padding-left:58px; 
        font-size: .875rem; 
    }

    .courier-info-layanan {
        width:100%;
    }
 
    .term-wrapper {
        padding: 50px 15px;
    }
}