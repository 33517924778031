.Profile {
    text-align: center;
  }
  
  .Profile {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Profile-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .Profile-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Profile-link {
    color: #61dafb;
  }
  
  @keyframes Profile-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  
    
  }

  .TopHeader{

    width: 100%;
    height: 160px;
    background: white;
  }

  .TopHeaderName{
    margin-left: 255px;
    padding-top: 60px;
    font-size: 30px;
    line-height: 35px;
    color: #4F4F4F;
    font-weight: bold;
  }

  @media (max-width: 464px) {
    .TopHeaderName{
    margin-left: 40px;
    padding-right: 40px;
  }  
  }
  
  .TopHeaderNumber{
    margin-left: 255px;
    padding-top: 5px;
    font-size: 20px;
    line-height: 23px;
    color: #4F4F4F;
    font-weight: 500;
  }

  @media (max-width: 464px) {
    .TopHeaderNumber{
    margin-left: 40px;
    padding-right: 40px;
  }  
  }

  .profileMenu{
      width: 100%;
      background-color: white;
  }

  .profileJudul{
    padding-top: 32px;
    padding-left: 40px;
    padding-bottom: 30px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    color: #4F4F4F;
  }

  .profileLink{
    padding-top: 10px;
    padding-left: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #BDBDBD;
  }

  .profileLink:hover{

    text-decoration-line: none;
    color: teal;
  }

  

  ul.sidenav-list-menu {
    list-style-type: none;
    
}


ul.sidenav-list-menu > li {
    padding-bottom: 20px;
    color: #BDBDBD;
  
}

ul.sidenav-list-menu > li a{  
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #BDBDBD;
  
}

ul.sidenav-list-menu > li a:hover{  
text-decoration: none;
color: var(--primary);

}

ul.sidenav-list-menu > li a.active{  
  color: var(--primary);

  }

.profileContainer{

    width: 100%;
    min-height: 100vh;
    background-color: white;
    padding-bottom: 50px;
}

.dataDiriJudul{

    padding-top: 15px;
    padding-left: 0px;
    padding-bottom: 15px;
    font-size: 24px;
    font-weight: 500;
    line-height: 21px;
    color: #4F4F4F;
}

@media (max-width: 464px) {
  .judulContainer{
    padding-left: 40px;
    padding-right: 40px;
}

  
}

.judulContainer{
  padding-top: 20px;
}

.dataDiriJudul2{ 
  padding:15px 0; 
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: #4F4F4F;
}

.hr-line{
    
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    width: 92%;
    height: 1px;
    margin-bottom: 20px;
    
}

@media (max-width: 464px) {
  .hr-line{
    width: 80%;
    margin-left: 35px;
    margin-top: 10px;
}
}

.content{

    padding-bottom: 5px;
    font-size: 14px;
    line-height: 14px;
    font-weight:normal;
    color: #4F4F4F;
}


.contentIsi{
    font-size: 16px;
    line-height: 16px;
    font-weight:bold;
    color: #4F4F4F;

}

.Edit-btn{
  margin-top: 30px;
  width: 60px;
  height: 30px;
  background-color: white;
  border: solid 1px var(--primary);
  border-radius: 5px;
  font-size: 12px;
  color:var(--primary);
}

.Save-btn{
  margin-top: 10px;
  margin-left: 620px;
  width: 290px;
  height: 44px;
  background-color: var(--primary);
  border: solid 1px var(--primary);
  font-size: 14px;
  color:white;
}

@media (max-width: 575.98px) {
  .Save-btn{
    width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left:0px ;
}  
}

.text-input{
  margin-top: 2px;
  width: 70%;
  height: 30px;
  padding: 8px 18px;
  font-size: 14px;
  color: #BDBDBD;
  background-color: #fff;
  border: 1px solid #BDBDBD;
  border-radius: 4px;
  margin-bottom: 5px;
  transition: all 250ms ease-in-out; 

}
.text-input:focus{
  outline: none;
  box-shadow: 0px 0px 5px 0.8px var(--primary);
}

.dataGroup{
  padding-bottom: 20px;
}

/* List item Keanggotaan */
ul.list-item-keanggotaan {
  list-style-type: none;
  padding:0;

  /* menghilangkan margin  */
  margin-left:-15px;
  margin-right:-15px;
}

.list-item-keanggotaan li {
  position:relative;
}

.list-item-keanggotaan a, .list-item-keanggotaan .list-item-title {
  display:block;
  background:#fff;
  border-bottom:1px solid #ccc; 
  color: #212529;
}

.list-item-keanggotaan .list-item-text { 
  padding:16px 16px 16px 60px;
}

.list-item-keanggotaan img { 
  position:absolute;
  top:16px;
  left:24px;
  height:21px;
}

.list-item-keanggotaan .list-item-title { 
  font-weight: bold; 
  padding:16px 20px;
}

.EmptyState{
  margin-left: 200px;
  padding-left: 20px;
}

.EmptyState .teks{
  padding-left: 40px;
}

.keanggotaan-container {
  max-width: 375px;
  margin:auto;
}

/* Small Device */
@media (max-width: 464px) {
  .dataDiriJudul2 {
    /* padding-left: 40px;
    padding-right: 40px; */
  }
  
  .dataGroup{
    padding-left: 40px;
    padding-right: 40px;
  }  

  
  /* List item Keanggotaan */
  ul.list-item-keanggotaan {  
    /* menghilangkan margin  */
    margin-left:-15px;
    margin-right:-15px;
  }
}

.Add-Address-btn{
  margin-top: 30px;
  width: 178px;
  height: 34px;
  background-color: var(--primary);
  border: solid 1px var(--primary);
  border-radius: 8px;
  font-size: 12px;
  color:white;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}

.Address-Labels{
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #565656;
}

.Address-Phone{
  font-weight: normal;
  
  font-size: 16px;
  line-height: 19px;
  color: #565656;
  padding-bottom: 7px;
}

.Address-Address{
  font-weight: normal;
  
  width: 452px;
  font-size: 16px;
  line-height: 19px;
  color: #565656;
  padding-bottom: 38px;
  
}

.Address-Edit-btn{
  width: 59px;
  height: 18px;
  padding-top: 0px;
  margin-left: 0px;
  background-color: white;
  border: solid 1px var(--primary);
  border-radius: 3px;
  font-size: 12px;
  color:var(--primary);
}
  
.Address-Delete-btn{
  width: 59px;
  height: 18px;
  padding-top: 0px;
  margin-left: 50px;
  background-color: white;
  border: solid 1px #4F4F4F;
  border-radius: 3px;
  font-size: 12px;
  color:#4F4F4F;
}

@media (max-width: 575.98px) {
  .Add-Address-btn{
    margin-left: 40%;
    margin-top: 10px;
    padding-bottom: 10px;
    
  } 

  .Address-Labels{
    margin-left: 40px;
    margin-top: 20px;
  }

  .Address-Phone{
    margin-left: 40px;
    
  }

  .Address-Address{
    margin-left: 40px;
    width: 80%;
    padding-bottom: 5px;
  }

  .Address-Edit-btn{
    margin-left: 50%;
    }

  .Address-Delete-btn{
    margin-left: 20px;
  }
  .EmptyState{
    margin-left: 10%;
    padding-left: 20px;
    
  }

    
}




  