.page-title {
  font-size: 18px;
}

.page-title-grey {
  color: #828282;
}

.product-category-card {
  background: #fff;
  border: 1px solid #fff;
}

.product-mart-list {
  /* border: 1px solid red;  */
  margin-left: -4px !important;
}

.product-category-card .card-header {
  font-size: 20px;
  color: #4f4f4f;
  font-weight: 500;
  background: #fff;
}

.sidenav-content {
  margin: 24px;
}

.sidenav-icon-cart {
  position: absolute;
  left: 25px;
}

.sidenav-btn {
  position: relative;
}

ul.sidenav-list {
  list-style-type: none;
}

ul.sidenav-list > li {
  margin-left: -38px;
  color: var(--primary);
  font-size: 14px;
  padding: 8px 0;
  cursor: pointer;
}

.product-list-title {
  font-size: 24px;
  text-align: center;
  color: #4f4f4f;
  margin: 29px 0 11px 0;
  font-weight: 500;
}

.product-detail-card,
.product-summary-card {
  background: white;
  border-radius: 3px;
  position: relative;
}

.empty-product {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.empty-product img {
  margin-top: 48px;
  margin-bottom: 24px;
}

.btn-qty {
  font-size: 14px;
}

/* Product Detail */

.product-detail-card {
  min-height: 235px;
  padding: 0px;
  margin: 8px 0px;
  border-radius: 16px;
  border: 1px solid #c4c4c4;
  margin-top: 24px;
}

.product-detail-card .product-content {
  padding-left: 24px;
}

.product-detail-card .product-unit-box {
  margin-top: 12px;
}

.stock-kosong-btn {
  background: #fff !important;
  color: #565656 !important;
  border: 1px solid #c4c4c4;
}

/* Desktop view */
@media only screen and (min-width: 1024px) {
  .product-detail-card .product-content {
    margin: 0 60px;
  }

  .product-detail-card .product-unit-box {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .product-detail-card .product-unit-box .product-detail-stock {
    text-align: left;
  }

  .product-detail-card .strip-price {
    display: inline-block !important;
    padding: 6px 10px 0 10px;
  }

  .product-detail-card .product-price {
    font-size: 24px !important;
  }
}

.product-detail-card .product-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #4f4f4f;
  padding-bottom: 18px;
  margin-top: 32px;
}

.product-detail-card .product-price {
  color: #4f4f4f;
  font-weight: bold;
  font-size: 20px;
  padding-top: 14px;
}

.product-detail-card .product-price-discount {
  color: #fd0808;
}

.product-detail-card .strip-price {
  text-decoration: line-through;
  color: #828282;
  font-size: 20px;
  display: block;
  /* padding-top:6px; */
  font-weight: normal;
}

.product-detail-card .label-discount {
  background: url("./../../../Images/shape-discount-detail.png") 0% 0% no-repeat;
  color: #fff;
  width: 56px;
  height: 29px;
  font-size: 12px;
  text-align: center;
  padding: 6px 0px;
  margin-top: 6px;
}

.product-detail-card .product-unit {
  font-weight: normal;
  font-size: 14px;
}

.product-detail-card .product-unit a {
  color: #3476da;
}

.product-detail-card .label-unit {
  color: #828282;
  font-size: 14px;
}

.input-product-qty.input-space {
  margin: 0 5%;
  width: 90%;
}

/* product summary */
.product-summary-card {
  padding: 24px;
}

.product-summary-card .box-total {
  background: #f2f2f2;
  padding: 10px;
  width: 100%;
}

.product-summary-card .box-total .label-total {
  font-size: 12px;
}

.product-summary-card .box-total .total-price {
  font-size: 16px;
  font-weight: bold;
}

.product-line {
  border-bottom: 1px solid #d8d8d8;
}

.product-category-card a {
  font-size: 14px;
  color: #4f4f4f;
}

.product-category-card a.active {
  font-weight: bold;
  color: var(--primary);
}

.descFont {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  padding-left: 35px;
  margin-top: 14px;
}

.descFont2 {
  font-size: 14px;
  font-weight: normal;
  /* line-height: 140%; */
  color: #4f4f4f;
  /* padding-bottom: 37px; */
  padding-right: 30px;
  /* margin:0 20px; */
  margin-top: 14px;
}

.detailImg {
  /* margin-left: 50px;
    margin-top: 20px; */
  margin: 2rem;
}

.btnprodukDetail {
  border-radius: 8px;
}

/* Custom Produk lainnya carousel */
.produklain-carousel {
  position: relative;
  margin: 0 20px 0 20px;
}
.produklain-carousel .react-multi-carousel-list {
  position: static;
}
.produklain-carousel .react-multiple-carousel__arrow::before {
  content: "";
}
.produklain-carousel .react-multiple-carousel__arrow:focus,
.produklain-carousel .react-multiple-carousel__arrow:hover {
  border: none;
  background-color: none;
  outline: 0;
}
.produklain-carousel .react-multiple-carousel__arrow {
  /* border:1px solid red;  */
  background: none;
  /* min-width:32px !important; */
  /* min-height:32px !important; */
}
.produklain-carousel .react-multiple-carousel__arrow--left {
  left: -55px;
  background-image: url("../../../Images/carousel-arrow-left.png");
}
.produklain-carousel .react-multiple-carousel__arrow--right {
  right: -55px;
  background-image: url("../../../Images/carousel-arrow-right.png");
}

.product-address-store-box {
  margin-top: 5px;
  padding-bottom: 20px;
}

/* Mobile View */
@media (max-width: 575.98px) {
  .detailImg {
    margin: 16px auto;
  }

  .product-detail-card {
    padding: 0px;
    border: none;
    margin-top: 10px;
  }

  .descFont2 {
    margin-left: 0px;
    margin-top: 7px;
    padding-right: 10%;
    padding-bottom: 0px;
  }

  .descFont {
    margin-left: 0px;
    padding-left: 0px;
    margin-top: 7px;
  }
  .produklain-carousel {
    margin-left: 0px;
    width: 100%;
  }

  .product-line {
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 2px solid #d1d1d1;
    padding-bottom: 0px;
  }

  .product-detail-card .product-content {
    padding-left: 0px;
    padding-top: 31px;
  }

  .product-detail-card .product-title {
    margin-top: 14px;
    padding-bottom: 4px;
  }

  .product-detail-card .product-price {
    padding-top: 0px;
  }

  .product-unit {
    padding-bottom: 0px;
  }

  .product-address-store-box {
    padding-bottom: 0px;
  }

  .product-detail-card .product-unit-box {
    padding-bottom: 16px;
  }
}
