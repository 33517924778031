
/* Product */
.product-item-card { 
    background: #fff;
    border: 1px solid #C4C4C4; 
    border-radius: 8px;
    position:relative;  
    height: 255px;   
    
    margin: 0 5px 30px 5px; 
} 

.react-multi-carousel-item:first-child .product-item-card {
    
    /* border: 1px solid red;  */
    margin-left:0px !important;
}

.product-item-card
.discount-image {
    position:absolute;
    top:-1px;
    right:-1px;
    background:url('./../../../Images/shape-discount-list.png') 0% 0% no-repeat;
    width:33px;
    height: 55px;
    color:#fff;
    font-size: 12px;
    padding:5px 0px;
    text-align: center;
    font-weight: bold;
    border-top-right-radius: 8px;
}

.product-item-card 
.product-title {
    font-weight: bold; 
    line-height: 16px;
    padding-top: 8px;
    font-size:14px; 
    height: 40px;
         
    /* limit text */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    
}

.product-item-card 
.product-title a {
    color:#5A5A5A;
}

.product-item-card 
.product-strip-price {
    color: #828282;
    font-size: .875rem;
    text-decoration: line-through; 
    display:inline-block;
}

.product-item-card 
.product-price {
    color: var(--primary);
    font-weight: 900;  
    font-size: 14px; 
    line-height: 16px;
    /* min-height: 30px; */
}

.product-item-card 
.product-price-discount {
    color: #FD0808;
    font-weight: bold;  
}

.product-item-card 
.product-unit {
    font-size:.75rem;
    font-weight: normal;
    color: #000000;
    padding-top:8px;
    
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    
}

.product-item-card 
.product-content { 
    padding: 12px 5px 16px 12px;
}
 
.product-item-card
.btn-stock-kosong {
    background:#fff !important;
    color: #565656 !important;
    border: 1px solid #D8D8D8 !important; 
    
    }
 
.product-item-card
.btn-beli {
    /* margin-top:5px; */
    border:1px solid var(---app-danger-color);
}

.product-item-card
.btn-product { 
    font-size: .875rem;
    border-radius: 0px 0px 8px 8px !important;
    width: 101.3%;
    margin-left: -1px;
    
}


.btn-minus{
    width: 30px !important;
    height: 30px !important;
    padding:0px !important;
    border-radius: 0px 0px 0px 8px !important;
    margin-left:-1px;

}

.btn-plus{
    width: 30px !important;
    height: 30px !important;
    padding:0px !important;
    margin-left: 0px !important;
    border-radius:  0px 0px 8px 0px !important;
    margin-right: -1px;
}

.product-item-card
.input-product-qty { 
    font-size: .875rem;
    width: 100% !important;
    height: 30px !important;
    border-radius: 2px !important;
    }

.product-item-card
.button-group-qty { 
    display: flex;
    flex-wrap: nowrap;
    background: white;

}

.product-item-card
.product-input-wrapper {
    margin:0 3px !important;
   
}

/* Default in large size */
.product-item-card
.product-image-wrapper {
    width:100%;
    height:125px; 
    cursor:pointer;
    padding: 7px 10px 0px 10px;
}

.product-item-card
.product-image-wrapper img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.react-multi-carousel-item 
.product-image-wrapper {
    height: 140px !important;
}

.btnPreorder{
    border-radius: 5.96264px;
    width: 70px;
    height: 16px;
    font-size: 10px;
    color:#FF0000;
    font-weight: bold;
    padding-bottom: 17px;
    /* padding-left: 10px; */
    margin-left: 48%;
    border: 1.29545px solid #FF0000;
    text-align:center;
}

.madeOrderBox{   
    font-size: 10px;
    color:#001580;
    font-weight: bold;
    /* margin-bottom: 17px; */
    /* padding-left: 10px; */ 
    border: 1.29545px solid #001580;
    text-align:center;
    background:#ECEFFF;
    border-radius:6px;
    padding:2px;
}

/* Small And Medium devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) { 
    .react-multi-carousel-item 
    .product-image-wrapper {
        height: 95px !important;
    }

    .product-item-card
    .product-image-wrapper { 
        height:122px !important;  
    }
    .btnPreorder{
        margin-left: 0px;
    } 
} 

.line-limit-2{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:2;
    overflow: hidden;
}

.btnPosition{
    margin-top: 5px;
}

@media (max-width: 991.98px) { 
    .react-multi-carousel-item 
    .product-image-wrapper {
        height: 120px !important;
    }
    
} 

@media (max-width: 575.98px) {

    .product-item-card{
        margin:0 5px 50px 5px;
    }
    .product-item-card .product-content{
        padding: 12px 12px 7px 12px;
    
    }

    .product-item-card 
    .product-price {
        height: 30px;
}

    .product-item-card
    .product-image-wrapper { 
        height:130px !important;  
    }

    /* .product-item-card{
        height: 230px;
        width: 175px;
        margin: 0 5px 20px 30px;
       
       } */

       .btnPosition{
           margin-top: 0px;
       }


}